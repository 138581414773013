import React, { FC, useContext, useEffect } from 'react'
import MapContextProvider from 'sav-features/map/MapContextProvider'
import VerticalResizeHandler from 'sav-features/sidebar/VerticalResizeHandler'
import MapFeatures from 'view-project/MapFeatures'
import SidebarData from 'view-project/SidebarData'
import { SidebarContext } from 'sav-features/sidebar/SidebarContextProvider'
import { useLocation } from 'react-router-dom'
import ConfusionMatrixProvider from 'sav-features/confusion-matrix/ConfusionMatrixContextProvider'
import FeasyContextProvider from 'sav-features/feasibility-mask/FeasyContextProvider'
import ValidationContextProvider from 'sav-features/validation/ValidationContextProvider'
import { ViewProjectContext } from 'sav-features/view-project/ViewProjectContextProvider'
import ProductContextProvider from 'sav-features/product/ProductContextProvider'
import HabitatLayerProvider from 'sav-features/habitat-layer/HabitatLayerContextProvider'
import PreprocessContextProvider from 'sav-features/preprocess/PreprocessContextProvider'
import LayersPanelContextProvider from 'sav-features/layers-panel/LayersPanelContextProvider'
import OrthoContextProvider from 'sav-features/orthophoto/OrthoContextProvider'
import BrightnessContextProvider from 'sav-features/brightness/BrightnessContextProvider'
import OrthoWrapper from './OrthoWrapper'

const ViewProject: FC = () => {
	const location = useLocation()
	const {
		actions: { setWidth },
		state: { width },
	} = useContext(SidebarContext)
	const {
		state: { currentProject },
	} = useContext(ViewProjectContext)
	useEffect(() => {
		setWidth(400)
	}, [location, setWidth])

	return (
		<ConfusionMatrixProvider currentProject={currentProject}>
			<ValidationContextProvider>
				<VerticalResizeHandler
					slotLeft={
						<MapContextProvider>
							<FeasyContextProvider
								currentProject={currentProject}
								isSwedishSav
							>
								<HabitatLayerProvider currentProject={currentProject}>
									<PreprocessContextProvider currentProject={currentProject}>
										<BrightnessContextProvider>
											<LayersPanelContextProvider>
												<OrthoContextProvider>
													<OrthoWrapper>
														<MapFeatures />
													</OrthoWrapper>
												</OrthoContextProvider>
											</LayersPanelContextProvider>
										</BrightnessContextProvider>
									</PreprocessContextProvider>
								</HabitatLayerProvider>
							</FeasyContextProvider>
						</MapContextProvider>
					}
					slotRight={
						<ProductContextProvider
							projectId={currentProject && String(currentProject.id)}
						>
							<SidebarData />
						</ProductContextProvider>
					}
					sidebarWidth={width}
					handleSidebarWidth={setWidth}
				/>
			</ValidationContextProvider>
		</ConfusionMatrixProvider>
	)
}

export default ViewProject
