import React, {
	FC,
	ReactNode,
	createContext,
	useState,
	useCallback,
	useContext,
	useEffect,
} from 'react'
import getData from 'sav-utils/getData'
import { useAuth0 } from '@auth0/auth0-react'
import { useSnackbar } from 'notistack'
import { AppContext } from '../app-screen/AppContextProvider'
import { ConfusionMatrixType } from './types'
import { ProjectType } from '../app-screen/types'

interface ConfusionMatrixContextValues {
	state: {
		confusionMatrix: ConfusionMatrixType | undefined
	}
	actions: {
		setConfusionMatrix: (s: ConfusionMatrixType | undefined) => void
		handleConfusionMatrix: () => Promise<void>
	}
}

interface Props {
	children: ReactNode
	currentProject: ProjectType | undefined
}

type Context = ConfusionMatrixContextValues
export const ConfusionMatrixContext = createContext<Context>(
	null as unknown as Context,
)

const ConfusionMatrixContextProvider: FC<Props> = ({
	children,
	currentProject,
}) => {
	const { getAccessTokenSilently } = useAuth0()
	const { enqueueSnackbar } = useSnackbar()
	const [confusionMatrix, setConfusionMatrix] = useState<
		undefined | ConfusionMatrixType
	>(undefined)
	const {
		actions: { setIsLoading },
	} = useContext(AppContext)

	const handleConfusionMatrix = useCallback(async () => {
		try {
			setIsLoading(true)
			const response = await getData(
				`/confusion-matrix?project_id=${String(
					currentProject?.id,
				)}&product_id=${Number(currentProject?.last_product?.product_id)}`,
				await getAccessTokenSilently(),
			)
			setConfusionMatrix(response.confusion_matrix)
		} catch (err) {
			enqueueSnackbar(`Confusion matrix could not be received`, {
				variant: 'error',
				autoHideDuration: 8000,
			})
		} finally {
			setIsLoading(false)
		}
	}, [
		currentProject?.id,
		currentProject?.last_product?.product_id,
		enqueueSnackbar,
		getAccessTokenSilently,
		setIsLoading,
	])

	useEffect(() => {}, [])

	return (
		<ConfusionMatrixContext.Provider
			value={{
				state: {
					confusionMatrix,
				},
				actions: {
					setConfusionMatrix,
					handleConfusionMatrix,
				},
			}}
		>
			{children}
		</ConfusionMatrixContext.Provider>
	)
}
export default ConfusionMatrixContextProvider
