import React, {
	FC,
	useState,
	useMemo,
	useContext,
	useCallback,
	useEffect,
} from 'react'
import {
	Box,
	Dialog,
	Typography,
	DialogActions,
	Button,
	TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { HexColorPicker } from 'react-colorful'
import postData from 'sav-utils/postData'
import { useAuth0 } from '@auth0/auth0-react'
import { useSnackbar } from 'notistack'
import shadeBlend from 'shade-blend-color'
import { AppContext } from '../app-screen/AppContextProvider'
import { ActiveClasses, DataClass } from './types'
import { ViewProjectContext } from '../view-project/ViewProjectContextProvider'
import TrainingDataClassPreviewSvg from './TrainingDataClassPreviewSvg'
import { TrainingDataContext } from './TrainingDataContextProvider'

const mapPreview =
	'https://grasdatastorage.blob.core.windows.net/images/se-sav-21-map_preview-classes.png'
const useStyles = makeStyles({
	icon: {
		width: 30,
		height: 30,
	},
	mapPreviewImage: {
		width: '100%',
		height: 'auto',
	},
})

interface Props {
	onClose: () => void
	open: boolean
	activeEditDataCategory: DataClass | undefined
}
const AddTrainingClassesDialog: FC<Props> = ({
	open,
	onClose,
	activeEditDataCategory,
}) => {
	const {
		state: { currentProject },
	} = useContext(ViewProjectContext)
	const {
		actions: { setDataCategories, handleGetPixelCount },
	} = useContext(TrainingDataContext)
	const {
		actions: { setIsLoading },
	} = useContext(AppContext)

	const classes = useStyles()
	const [color, setColor] = useState<undefined | string>(undefined)
	const [className, setClassName] = useState<string | undefined>(undefined)

	const { getAccessTokenSilently } = useAuth0()
	const { enqueueSnackbar } = useSnackbar()
	const handleOnClose = useCallback(() => {
		onClose()
		setClassName(undefined)
		setColor(undefined)
	}, [onClose])

	const getMappedClasses = (localClasses: ActiveClasses[]) =>
		localClasses.map((item: ActiveClasses) => ({
			className: item.class_name,
			classColor: item.class_color,
			classId: item.class_id,
		}))

	const handleAddNewClass = useCallback(async () => {
		try {
			setIsLoading(true)
			const response = await postData(
				'/add-training-data-class',
				await getAccessTokenSilently(),
				{
					project_id: currentProject?.id,
					class_name: className,
					class_color: color,
				},
			)
			const mappedActiveClasses = getMappedClasses(response.active_classes)

			handleOnClose()
			setDataCategories(mappedActiveClasses)
		} catch (err) {
			enqueueSnackbar('Something went wrong.', {
				variant: 'error',
				autoHideDuration: 8000,
			})
		} finally {
			setIsLoading(false)
		}
	}, [
		className,
		color,
		currentProject?.id,
		enqueueSnackbar,
		getAccessTokenSilently,
		handleOnClose,
		setDataCategories,
		setIsLoading,
	])

	const handleUpdateClass = async () => {
		try {
			setIsLoading(true)
			const response = await postData(
				'/modify-training-data-class',
				await getAccessTokenSilently(),
				{
					project_id: currentProject?.id,
					class_name: className,
					class_color: color,
					class_id: activeEditDataCategory?.classId,
				},
			)
			await handleGetPixelCount()
			const mappedActiveClasses = getMappedClasses(response.active_classes)

			setDataCategories(mappedActiveClasses)
			handleOnClose()
		} catch (err) {
			enqueueSnackbar('Something went wrong.', {
				variant: 'error',
				autoHideDuration: 8000,
			})
		} finally {
			setIsLoading(false)
		}
	}

	const errorClassName = useMemo(() => {
		if (className === undefined || className === '') return false
		if (className.length > 10) return 'Max 10 carachters allowed'
		if (className.split(' ')[1]) return 'No spaces allowed'
		return false
	}, [className])

	const errorColor = useMemo(() => {
		if (color === undefined || color === '') return false
		if (color?.[0] !== '#') return 'The color must start with #'
		if (color?.split('#')[1].length !== 6)
			return 'Color must have 6 carachters after #'
		return false
	}, [color])

	const isButtonDisabled = useMemo(
		() =>
			!errorColor &&
			!errorClassName &&
			color !== undefined &&
			color !== '' &&
			className !== undefined &&
			className !== '',
		[errorColor, errorClassName, color, className],
	)

	const trainingDataColor = useMemo(
		() => color && (shadeBlend(-0.25, color) as string),
		[color],
	)

	useEffect(() => {
		setClassName(activeEditDataCategory?.className)
		setColor(activeEditDataCategory?.classColor)
	}, [activeEditDataCategory])

	return (
		<Dialog open={open} onClose={handleOnClose} maxWidth="xs">
			<Box p={2}>
				<Typography variant="h3" gutterBottom>
					{activeEditDataCategory ? 'Update class' : 'Add new class'}
				</Typography>
				<Box my={2}>
					<Box my={2}>
						<Typography variant="body1">
							Pick a color and a name for your custom class to use within this
							project.
						</Typography>
					</Box>
					<Box display="flex">
						<Box mr={0.5} width="50%">
							<Box mb={2}>
								<TextField
									error={Boolean(errorClassName)}
									fullWidth
									label="Class name"
									value={className}
									onChange={(e) => setClassName(e.target.value)}
									variant="outlined"
									helperText={errorClassName}
								/>
							</Box>
							<Box mt={2}>
								<TextField
									error={Boolean(errorColor)}
									id="color-input"
									label="Color"
									value={color ?? ''}
									onChange={(e) => setColor(e.target.value)}
									variant="outlined"
									helperText={errorColor}
								/>
							</Box>
						</Box>
						<Box display="flex" ml={2} width="50%">
							<HexColorPicker
								color={color}
								onChange={setColor}
								style={{ height: '100%' }}
							/>
						</Box>
					</Box>

					<Box mt={2} mb={1}>
						<Typography variant="h4" gutterBottom>
							Preview
						</Typography>
						<Box position="relative">
							<img
								alt="map-preview-color"
								src={mapPreview}
								className={classes.mapPreviewImage}
							/>
							<Box position="absolute" top={0} left={0}>
								{color && (
									<TrainingDataClassPreviewSvg
										classColor={color}
										trainingColor={trainingDataColor}
									/>
								)}
							</Box>
						</Box>
					</Box>
					<Typography variant="body2">
						<b>Note:</b> Classes, once created, cannot be deleted from the
						current project.
					</Typography>
				</Box>
				<DialogActions>
					<Box mr={0.5}>
						<Button size="small" variant="outlined" onClick={handleOnClose}>
							Cancel
						</Button>
					</Box>
					<Box ml={0.5}>
						<Button
							size="small"
							variant="contained"
							disabled={!isButtonDisabled}
							onClick={
								activeEditDataCategory ? handleUpdateClass : handleAddNewClass
							}
						>
							{activeEditDataCategory ? 'Update class' : 'Add class'}
						</Button>
					</Box>
				</DialogActions>
			</Box>
		</Dialog>
	)
}

export default AddTrainingClassesDialog
