import React, { FC, useContext, useCallback } from 'react'
import { DrawPolygonMode } from '@nebula.gl/edit-modes'
import { Box } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { ValidationContext } from '../validation/ValidationContextProvider'
import TrainingDataUpload from './TrainingDataUpload'
import TrainingDataContent from './TrainingDataContent'
import TrainingDataHeader from './TrainingDataHeader'
import { TrainingDataContext } from './TrainingDataContextProvider'
import { UploadRefOption } from './types'

export interface Props {
	uploadOptions: UploadRefOption[]
	noActionButtons?: boolean
	isExtent?: boolean
}

const SidebarTrainingData: FC<Props> = ({
	uploadOptions,
	noActionButtons = false,
	isExtent = false,
}) => {
	const { enqueueSnackbar } = useSnackbar()
	const {
		state: { draw },
		actions: { setDraw },
	} = useContext(TrainingDataContext)

	const {
		state: { showValidation },
	} = useContext(ValidationContext)

	const startDrawing = useCallback(
		(title: string, color: string) => {
			if (showValidation) {
				enqueueSnackbar(
					'You have to disable the validation to change the training data.',
					{
						variant: 'info',
						autoHideDuration: 8000,
					},
				)
			}
			if (color === draw?.color) setDraw(undefined)
			else setDraw({ mode: new DrawPolygonMode(), title, color })
		},
		[showValidation, draw?.color, setDraw, enqueueSnackbar],
	)

	return (
		<Box display="block">
			<TrainingDataHeader
				startDrawing={startDrawing}
				maxClasses={isExtent ? 8 : 5}
			/>
			<TrainingDataContent />
			{!noActionButtons && <TrainingDataUpload uploadOptions={uploadOptions} />}
		</Box>
	)
}

export default SidebarTrainingData
