import React, { FC, useContext } from 'react'
import { Box, Typography, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { IMikeTheme } from '@dhi/react-components-lab'
import {
	VisibilityOffOutlined as ToggleOffIcon,
	VisibilityOutlined as ToggleOnIcon,
} from '@material-ui/icons'
import { useTitleStyles } from '../create-project/styles'
import { OrthoContext } from './OrthoContextProvider'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	icon: {
		width: 18,
		height: 18,
		color: theme.palette.primary.main,
	},
	checkboxControl: {
		flexGrow: 1,
	},
}))

const OrthoControl: FC = () => {
	const {
		state: { showOrtho },
		actions: { setShowOrtho },
	} = useContext(OrthoContext)
	const classes = useStyles()
	const titleStyles = useTitleStyles()

	return (
		<>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Typography variant="body2" className={titleStyles.subtitle}>
					Orthophoto
				</Typography>
				<Box ml={1}>
					<IconButton size="small" onClick={() => setShowOrtho(!showOrtho)}>
						{showOrtho ? (
							<ToggleOnIcon className={classes.icon} />
						) : (
							<ToggleOffIcon className={classes.icon} />
						)}
					</IconButton>
				</Box>
			</Box>
		</>
	)
}

export default OrthoControl
