import React, { FC, useContext, useRef, useMemo } from 'react'
import {
	Box,
	Button,
	Tooltip,
	Typography,
	IconButton,
	useTheme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PublishRoundedIcon from '@material-ui/icons/PublishRounded'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import { IMikeTheme } from '@dhi/react-components-lab'
import clsx from 'clsx'
import {
	VisibilityOffOutlined as ToggleOffIcon,
	VisibilityOutlined as ToggleOnIcon,
} from '@material-ui/icons'
import { useTitleStyles } from '../create-project/styles'
import { ConfusionMatrixContext } from '../confusion-matrix/ConfusionMatrixContextProvider'
import { ValidationContext } from './ValidationContextProvider'
import ConfusionMatrixExtended from '../confusion-matrix/ConfusionMatrixExtended'
import { TrainingDataContext } from '../training-data/TrainingDataContextProvider'
import {
	ConfusionMatrixDanishType,
	ConfusionMatrixSwedenType,
} from '../confusion-matrix/types'
import ConfusionMatrix from '../confusion-matrix/ConfusionMatrix'

const validationTemplateFile =
	'https://grasdatastorage.blob.core.windows.net/files/validation_template.zip'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	button: {
		border: `0.5px solid ${theme.palette.primary.main}33`,
		backgroundColor: theme.palette.background.paper,
		boxShadow: 'none',
		color: `${theme.palette.primary.main}`,
		padding: '2px 12px',
		'&:hover': {
			backgroundColor: theme.palette.grey[50],
		},
		'&:disabled': {
			backgroundColor: theme.palette.grey[50],
			color: theme.palette.grey[100],
		},
	},
	downloadButton: {
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	icon: {
		width: 18,
		height: 18,
		color: theme.palette.primary.main,
	},
}))

const Validation: FC = () => {
	const {
		state: { dataCategories },
	} = useContext(TrainingDataContext)

	const {
		state: { confusionMatrix },
	} = useContext(ConfusionMatrixContext)

	const {
		state: { validationData, isValidationLoading, showValidation },
		actions: { handleUploadValidation, handleShowValidation },
	} = useContext(ValidationContext)

	const theme = useTheme()
	const classes = useStyles()
	const titleStyles = useTitleStyles()
	const uploadFile: any = useRef(null)
	const downloadButton: any = useRef(null)
	const isOnSwedishDb =
		process.env.REACT_APP_DATABASE_PRO_URL?.includes('sesav-db')

	const dataCategoriesString = useMemo(() => {
		const classesArray = dataCategories?.map(
			(classObj) => `"${String(classObj.className)}"`,
		)
		if (classesArray) {
			const lastItem = classesArray[classesArray.length - 1]
			const withoutLastItem = classesArray.slice(0, classesArray.length - 1)
			return `${String(withoutLastItem.join(', '))} and/or ${String(lastItem)}`
		}
		return ''
	}, [dataCategories])

	const dataUploadIcon = validationData ? (
		<CheckCircleRoundedIcon htmlColor={theme.palette.success.main} />
	) : (
		<PublishRoundedIcon />
	)
	return (
		<Box>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Typography variant="body2" className={titleStyles.subtitle}>
					Validation data
				</Typography>
				<Box ml={1}>
					<IconButton
						size="small"
						onClick={() => handleShowValidation(!showValidation)}
					>
						{validationData && showValidation ? (
							<ToggleOnIcon className={classes.icon} />
						) : (
							<ToggleOffIcon className={classes.icon} />
						)}
					</IconButton>
				</Box>
			</Box>
			{confusionMatrix &&
				(isOnSwedishDb ? (
					<ConfusionMatrixExtended
						data={confusionMatrix as ConfusionMatrixSwedenType}
					/>
				) : (
					<ConfusionMatrix
						data={confusionMatrix as ConfusionMatrixDanishType}
					/>
				))}
			<Box display="flex" mt={1.5} justifyContent="space-between">
				<Box style={{ width: '100%' }}>
					<input
						accept=".zip"
						type="file"
						id="file-upload-json"
						style={{ display: 'none' }}
						ref={uploadFile}
						onChange={({ target }) => {
							void handleUploadValidation(target)
							// eslint-disable-next-line no-param-reassign
							target.value = ''
						}}
					/>
					<Tooltip
						title={`Zipped shapefiles or a zipped GeoJSON file containing a column named “class_name” with classes ${dataCategoriesString}.`}
					>
						<Button
							fullWidth
							variant="outlined"
							color="secondary"
							// className={classes.button}
							disableElevation
							startIcon={
								isValidationLoading ? (
									<CircularProgress size={16} />
								) : (
									dataUploadIcon
								)
							}
							onClick={() => uploadFile.current.click()}
						>
							Upload validation data
						</Button>
					</Tooltip>
					<Box mt={2} display="flex">
						<Button
							style={{ display: 'none' }}
							variant="contained"
							color="default"
							href={validationTemplateFile}
							download="validation_template.zip"
							className={classes.button}
							disableElevation
							startIcon={<GetAppRoundedIcon />}
							ref={downloadButton}
						/>

						<Typography
							variant="body2"
							className={clsx(classes.downloadButton, titleStyles.subtitle)}
							onClick={() => downloadButton.current.click()}
						>
							Download validation template
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default Validation
