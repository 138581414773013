import React, { FC, useState, useContext } from 'react'
import { Box, Typography, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Slider from 'sav-components/Slider'
import {
	VisibilityOffOutlined as ToggleOffIcon,
	VisibilityOutlined as ToggleOnIcon,
} from '@material-ui/icons'
import { CategoricalBarLegend, IMikeTheme } from '@dhi/react-components-lab'
import { FeasyContext } from './FeasyContextProvider'
import { useTitleStyles } from '../create-project/styles'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	icon: {
		width: 18,
		height: 18,
		color: theme.palette.primary.main,
	},
}))

const FeasyControl: FC = () => {
	const classes = useStyles()
	const titleStyles = useTitleStyles()
	const {
		state: { showFeasy },
		actions: { setShowFeasy, setFeasyOpacity },
	} = useContext(FeasyContext)
	return (
		<>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Typography variant="body2" className={titleStyles.subtitle}>
					Feasible area
				</Typography>
				<Box ml={1}>
					<IconButton size="small" onClick={() => setShowFeasy(!showFeasy)}>
						{showFeasy ? (
							<ToggleOnIcon className={classes.icon} />
						) : (
							<ToggleOffIcon className={classes.icon} />
						)}
					</IconButton>
				</Box>
			</Box>
			<Box position="relative" display="flex" height={45} alignItems="center">
				<Slider
					defaultValue={100}
					title="Opacity: "
					min={0}
					max={100}
					step={1}
					unit="%"
					getValueCommitted={(val) => setFeasyOpacity(Number(val))}
				/>
			</Box>
			<CategoricalBarLegend
				items={[
					{ color: '#337AAE', label: 'Shallow' },
					{ color: '#33AE9F', label: 'Shallow weak' },
					{ color: '#C5F8F9', label: 'Shallow gaps' },
				]}
			/>
		</>
	)
}

export default FeasyControl
