import React, { FC, ReactNode, createContext, useState } from 'react'

interface LayersPanelContextValues {
	state: {
		openLayersPanel: boolean
	}
	actions: {
		setOpenLayersPanel: (v: boolean) => void
	}
}

interface Props {
	children: ReactNode
}

type Context = LayersPanelContextValues
export const LayersPanelContext = createContext<Context>(
	null as unknown as Context,
)

const LayersPanelContextProvider: FC<Props> = ({ children }) => {
	const [openLayersPanel, setOpenLayersPanel] = useState(true)
	return (
		<LayersPanelContext.Provider
			value={{
				state: {
					openLayersPanel,
				},
				actions: {
					setOpenLayersPanel,
				},
			}}
		>
			{children}
		</LayersPanelContext.Provider>
	)
}
export default LayersPanelContextProvider
