import moment from 'moment'
import { DateWithClouds, ReducedDatesYears } from './types'

interface PayloadFilterByCloud {
	dates: ReducedDatesYears
	cloudProcentage: number
}

interface PayloadReducedDates {
	dates: DateWithClouds[]
}

export const getReducedDates = ({ dates }: PayloadReducedDates) => {
	const localYearsArray = dates?.reduce((accumulator, item: DateWithClouds) => {
		accumulator[moment(item.date).format('YYYY')] = [
			...(accumulator[moment(item.date).format('YYYY')] || []),
			item,
		]
		return accumulator
	}, {})
	if (localYearsArray) {
		Object.keys(localYearsArray).forEach((localYear) => {
			const arrayItem = localYearsArray[localYear].reduce(
				(acc, item: DateWithClouds) => {
					acc[moment(item.date).format('MMM')] = [
						...(acc[moment(item.date).format('MMM')] || []),
						item,
					]
					return acc
				},
				{},
			)

			localYearsArray[localYear] = arrayItem
		})
		return localYearsArray
	}
	return undefined
}

export const filterByCloudProcentage = ({
	dates,
	cloudProcentage,
}: PayloadFilterByCloud) => {
	const arrObj: ReducedDatesYears = {}
	Object.keys(dates)?.forEach((year: string) => {
		Object.keys(dates[year])?.forEach((month: string) => {
			arrObj[year] = {
				...arrObj[year],
				[month]: dates[year][month].filter(
					({ date, cloud_coverage }: DateWithClouds) =>
						cloud_coverage < cloudProcentage && date,
				),
			}
		})
	})

	Object.keys(arrObj).forEach((year: string) => {
		if (Object.keys(arrObj[year]).length === 0) {
			delete arrObj[year]
		} else {
			Object.keys(arrObj[year]).forEach((month: string) => {
				if (Object.keys(arrObj[year][month]).length === 0) {
					delete arrObj[year][month]
				}
			})
		}
	})

	return arrObj
}
