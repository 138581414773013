// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/no-array-index-key */
import React, {
	FC,
	useState,
	useRef,
	useEffect,
	MutableRefObject,
	useMemo,
} from 'react'
import { Box, Typography, Button, Dialog } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { IMikeTheme } from '@dhi/react-components-lab'
import ConfusionMatrixBox from './ConfusionMatrixBox'
import { ConfusionMatrixSwedenType } from './types'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	rowBox: {
		width: '100%',
		display: 'flex',
		flexWrap: 'nowrap',
		justifyContent: 'center',
	},
	axisLabel: {
		// fontSize: 10,
		fontWeight: 600,
	},
	rotate45: {
		transform: 'rotate(45deg)',
	},
	rotateMinus45: {
		transform: 'rotate(-45deg)',
	},
	actualClassLabel: {
		transform: 'rotate(-90deg)',
		height: 'fit-content',
		whiteSpace: 'nowrap',
		fontSize: 10,
		fontWeight: 400,
	},
	predictedClassLabel: {
		whiteSpace: 'nowrap',
		fontSize: 10,
		fontWeight: 400,
	},
}))

interface Props {
	data: ConfusionMatrixSwedenType
}

const ConfusionMatrix: FC<Props> = ({ data }) => {
	const [width, setWidth] = useState<number>(0)
	const [openDialog, setOpenDialog] = useState<boolean>(false)
	const classes = useStyles()

	const dialogWidth = 450

	useEffect(() => {
		const itemWidth = dialogWidth / data.row_labels.length
		setWidth(itemWidth)
	}, [data])

	const numberOfClasses = data.column_labels.length - 3

	return (
		<>
			<Box mt={1} width={1}>
				<Button
					fullWidth
					onClick={() => setOpenDialog(true)}
					variant="outlined"
				>
					Confusion matrix
				</Button>
			</Box>
			<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
				<Box>
					<Box width={1} my={1} display="flex" justifyContent="center">
						<Box
							borderRadius={4}
							display="flex"
							justifyContent="flex-end"
							alignItems="flex-end"
							boxShadow={2}
							p={0.5}
							width={1}
						>
							<Box display="flex" flexWrap="nowrap" alignItems="center">
								<Box width={20}>
									<Typography
										variant="body2"
										className={classes.actualClassLabel}
									>
										Actual class
									</Typography>
								</Box>
								<Box
									display="flex"
									height="fit-content"
									flexDirection="column"
									alignItems="center"
									justifyContent="flex-end"
								>
									{data.row_labels.map((label: string, i: number) => (
										<Box
											key={`y-axis-label-${i}`}
											height={width / 2}
											width="fit-content"
											overflow="auto"
											display="flex"
											alignItems="center"
											justifyContent="center"
											mx={0.5}
										>
											<Typography variant="body2" className={classes.axisLabel}>
												{label}
											</Typography>
										</Box>
									))}
								</Box>
							</Box>
							<Box width={1}>
								<Box width={1}>
									<Box width={1} display="flex" justifyContent="center">
										<Typography
											variant="body2"
											className={classes.predictedClassLabel}
										>
											Predicted class
										</Typography>
									</Box>
									<Box
										display="flex"
										width={1}
										alignItems="center"
										justifyContent="center"
									>
										{data.column_labels.map((label: string, i: number) => (
											<Box
												key={`x-axis-label-${i}`}
												width={width / 2}
												overflow="auto"
												display="flex"
												alignItems="center"
												justifyContent="center"
												flexGrow={1}
												my={0.5}
											>
												<Typography
													variant="body2"
													className={classes.axisLabel}
												>
													{label}
												</Typography>
											</Box>
										))}
									</Box>
								</Box>
								<Box
									width={1}
									display="flex"
									justifyContent="center"
									flexDirection="column"
								>
									{data.values.map((item: number[], i: number) => (
										<div className={classes.rowBox} key={`number-row-${i}`}>
											{item.map((localValue: number, j: number) => (
												<ConfusionMatrixBox
													numberOfClasses={numberOfClasses}
													displayValue={localValue}
													percentageValues={data.normalized_values}
													positionI={i}
													positionJ={j}
													width={width}
													height={width / 2}
													key={`confusion-matrix-item-${i}-${j}`}
												/>
											))}
										</div>
									))}
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Dialog>
		</>
	)
}

export default ConfusionMatrix
