import React, { FC, useState, ReactNode } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { useSessionStorage } from '@dhi/react-components-lab'

const useStyles = makeStyles((theme) => ({
	moreButton: {
		fontWeight: 600,
		color: theme.palette.secondary.main,
		cursor: 'pointer',
		'&:hover': {
			color: theme.palette.secondary.dark,
		},
	},
	descriptionWrapper: {
		width: '100%',
		backgroundColor: theme.palette.grey[50],
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16);',
		borderRadius: 4,
		display: 'flex',

		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundImage:
			"url('https://grasdatastorage.blob.core.windows.net/images/sav-2020-desc-bg.jpg')" /* W3C */,
	},
	icon: { width: 20, height: 20, cursor: 'pointer' },
	dashboardContent: {
		display: 'flex',
		backgroundImage: `linear-gradient(to right, ${theme.palette.background.paper} 50%, rgba(255,255,255,.7) 100%)`,
		borderRadius: 4,
	},
}))

interface Props {
	content: ReactNode
	extraContent: ReactNode
	showExtraContent: boolean
}

const Description: FC<Props> = ({
	content,
	extraContent,
	showExtraContent,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(true)

	const classes = useStyles()
	const [userPopupChoice, setUserPopupChoice] = useSessionStorage<
		boolean | undefined
	>('userPopupChoice')

	const handlePopupStatus = () => {
		setIsOpen(false)
		setUserPopupChoice(false)
	}

	return isOpen && userPopupChoice !== false ? (
		<Box className={classes.descriptionWrapper}>
			<Box className={classes.dashboardContent}>
				<Box p={2} maxWidth="65%">
					{content}

					{showExtraContent && extraContent}
				</Box>
				<Box p={1} flexGrow={1} display="flex" justifyContent="flex-end">
					<CloseRoundedIcon
						color="primary"
						className={classes.icon}
						onClick={() => handlePopupStatus()}
					/>
				</Box>
			</Box>
		</Box>
	) : (
		<></>
	)
}

export default Description
