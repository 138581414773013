import React, { FC, useContext, useEffect, useMemo } from 'react'
import { MapContext } from 'sav-features/map/MapContextProvider'
import Map from 'sav-features/map/Map'
import { GeoJsonLayer, PickInfo } from 'deck.gl'
import getViewportForFeature from 'sav-features/map/utils/getViewportForFeature'
import { SidebarContext } from 'sav-features/sidebar/SidebarContextProvider'
import { RegionsContext } from 'sav-features/regions/RegionsContextProvider'
import { FeatureWithProps } from 'sav-features/regions//types'
import { useTheme } from '@material-ui/core'

const MapFeatures: FC = () => {
	const theme = useTheme()
	const {
		state: { viewport, isOpticalBasemap, mapHeight, mapWidth },
		actions: { setViewport, handleViewport },
	} = useContext(MapContext)

	const {
		state: { activeRegion, regions },
		actions: { setActiveRegion },
	} = useContext(RegionsContext)

	const {
		state: { width },
	} = useContext(SidebarContext)

	const regionsLayer = new GeoJsonLayer({
		id: 'se-sav-regions',
		data: regions ?? undefined,
		stroked: true,
		filled: true,
		pickable: true,
		lineWidthMinPixels: 1,
		getLineWidth: 1,
		lineWidthUnits: 'pixels',
		getLineColor: () => [11, 69, 102, 255],
		getFillColor: () => [0, 0, 0, 0],
		onClick: (item: PickInfo<FeatureWithProps>) => setActiveRegion(item.object),
	})

	const activeRegionLayer = new GeoJsonLayer({
		id: 'se-sav-active-region',
		data: activeRegion ?? undefined,
		stroked: true,
		filled: true,
		lineWidthMinPixels: 1,
		getLineWidth: 3,
		lineWidthUnits: 'pixels',
		getLineColor: () => [0, 164, 236, 255],
		getFillColor: () => [0, 0, 0, 0],
	})

	const hasSizes = useMemo(
		() => Boolean(mapHeight !== undefined && mapWidth !== undefined),
		[mapWidth, mapHeight],
	)

	const hasRegions = useMemo(() => Boolean(regions), [regions])

	useEffect(() => {
		if (regions && mapWidth !== undefined && mapHeight !== undefined) {
			const localViewport = getViewportForFeature(
				activeRegion || regions,
				mapHeight,
				mapWidth,
			)
			handleViewport({ ...localViewport, transitionDuration: 2000 })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasSizes, hasRegions, regions, activeRegion, handleViewport])

	return (
		<Map
			getCursor={() => 'pointer'}
			view={viewport}
			setViewport={setViewport}
			layers={[regionsLayer, activeRegionLayer]}
			width={`calc(100vw - ${String(width)}px )`}
			themeType={theme.palette.type}
			getTooltip={({ object }) =>
				object && {
					text: object.properties.swedishName,
					style: {
						top: '-50px',
						left: `-${object.properties.swedishName.length * 4}px`,
						transform: 'translateX(-50%)',
						textSize: '12px',
					},
				}
			}
		/>
	)
}

export default MapFeatures
