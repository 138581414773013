import React, { useState, FC } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Typography, makeStyles, Tooltip } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import CircularProgress from '@material-ui/core/CircularProgress'
import { IMikeTheme } from '@dhi/react-components-lab'

export const buttonStyle = makeStyles((theme) => ({
	root: {
		border: `1px solid ${String(theme.palette.grey[100])}`,
		borderRadius: '6px 6px 0px 0px',
	},
	wrapper: {
		backgroundColor: theme.palette.grey[50],
		borderRadius: '6px 6px 0px 0px',
	},
	titleStyle: {
		fontSize: 24,
		fontWeight: 400,
		color: theme.palette.text.primary,
	},
	circularProgress: {
		position: 'absolute',
		color: theme.palette.success?.main,
		zIndex: 0,
	},
	refreshIcon: {
		width: 26,
		height: 26,
		cursor: 'pointer',
		zIndex: 10,
	},
}))

interface Props {
	onFetchProjects: () => Promise<void>
}

const DashboardHeader: FC<Props> = ({ onFetchProjects }) => {
	const classes = buttonStyle()
	const [tinyLoading, setTinyLoading] = useState<boolean>(false)

	const onRefresh = async () => {
		setTinyLoading(true)
		await onFetchProjects()
		setTinyLoading(false)
	}

	return (
		<Box width={1} className={classes.root} display="flex">
			<Box
				width={1}
				px={2}
				py={2.5}
				display="flex"
				justifyContent="space-between"
				className={classes.wrapper}
			>
				<Box display="flex">
					<Typography className={classes.titleStyle}>Projects</Typography>
				</Box>
				<Box display="flex">
					<Box mr={2} display="flex" alignItems="center" position="relative">
						{tinyLoading && (
							<CircularProgress
								size={26}
								className={classes.circularProgress}
							/>
						)}
						<Tooltip title="Refresh projects">
							<RefreshIcon
								color="primary"
								fontSize="large"
								className={classes.refreshIcon}
								onClick={onRefresh}
							/>
						</Tooltip>
					</Box>
					<Button variant="outlined" component={Link} to="/new-project">
						New project
					</Button>
				</Box>
			</Box>
		</Box>
	)
}

export default DashboardHeader
