import { useAuth0 } from '@auth0/auth0-react'
import { EditAction } from '@nebula.gl/edit-modes'
import saveAs from 'file-saver'
import { Feature, Polygon, FeatureCollection } from 'geojson'
import JSZip from 'jszip'
import { useSnackbar } from 'notistack'
import React, {
	FC,
	ReactNode,
	createContext,
	useState,
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from 'react'
import getData from 'sav-utils/getData'
import postData from 'sav-utils/postData'
import { EditableGeoJsonLayer, ViewMode } from 'nebula.gl'
import { GeoJsonLayer, PickInfo, RGBAColor } from 'deck.gl'
import shadeBlend from 'shade-blend-color'
import isWithin from '@turf/boolean-within'
import { AppContext } from '../app-screen/AppContextProvider'
import { RegionsContext } from '../regions/RegionsContextProvider'
import { ViewProjectContext } from '../view-project/ViewProjectContextProvider'
import {
	Draw,
	PixelData,
	AddTrainingDataPayload,
	RemoveTrainingDataPayload,
	DrawFeatureCollWithProps,
	DrawFeatureWithProps,
	DataClass,
	GetTrainingDataPayload,
	GetTrainingDataResponse,
	DownloadZipPayload,
	UploadZipPayload,
} from './types'
import { FeatureWithProps } from '../regions/types'

interface TrainingDataContextValues {
	state: {
		draw: Draw | undefined
		trainingFeatureColl: DrawFeatureCollWithProps | undefined
		selectedDrawFeature: DrawFeatureWithProps | undefined
		pixelCount: PixelData | undefined
		dataCategories: DataClass[] | undefined
		showTrainingData: boolean
	}
	actions: {
		setDraw: (d: Draw | undefined) => void
		setTrainingFeatureColl: (f: DrawFeatureCollWithProps | undefined) => void
		setSelectedDrawFeature: (f: DrawFeatureWithProps | undefined) => void
		setDataCategories: (d: DataClass[] | undefined) => void
		setPixelCount: (v: PixelData | undefined) => void
		getFeatureClassColor: (f: DrawFeatureWithProps) => RGBAColor
		setShowTrainingData: (b: boolean) => void
		handleRemoveTrainingData: (pay: RemoveTrainingDataPayload) => Promise<void>
		handleUploadZipFile: <T>(pay: UploadZipPayload) => Promise<T | undefined>
		handleDownloadZipFile: (pay: DownloadZipPayload) => Promise<void>
		handleDeleteAllTrainingData: () => Promise<void>
		handleGetPixelCount: () => Promise<void>
	}
	layers: {
		trainingDrawLayer: typeof EditableGeoJsonLayer
		trainingDataLayer: GeoJsonLayer<unknown>
	}
}

interface Props {
	children: ReactNode
	projectId: string
}

const responseKey = {
	'/upload-validation-data': 'validation_data',
	'/upload-training-data': 'training_data',
}

type Context = TrainingDataContextValues
export const TrainingDataContext = createContext<Context>(
	null as unknown as Context,
)

const emptyCollection: FeatureCollection = {
	type: 'FeatureCollection' as const,
	features: [],
}
const TrainingDataContextProvider: FC<Props> = ({ children, projectId }) => {
	const { getAccessTokenSilently } = useAuth0()
	const { enqueueSnackbar } = useSnackbar()
	const {
		actions: { setIsLoading },
	} = useContext(AppContext)
	const {
		state: { currentProject },
	} = useContext(ViewProjectContext)
	const {
		state: { activeRegion },
	} = useContext(RegionsContext)
	const [draw, setDraw] = useState<Draw | undefined>(undefined)
	const [trainingFeatureColl, setTrainingFeatureColl] = useState<
		DrawFeatureCollWithProps | undefined
	>(undefined)
	const [selectedDrawFeature, setSelectedDrawFeature] = useState<
		DrawFeatureWithProps | undefined
	>(undefined)
	const [dataCategories, setDataCategories] = useState<DataClass[] | undefined>(
		undefined,
	)
	const [showTrainingData, setShowTrainingData] = useState(true)
	const [pixelCount, setPixelCount] = useState<PixelData | undefined>(undefined)

	const getTrainingData = useCallback(
		async ({
			projId,
			showNotification = true,
			refSystem = 'epsg:4326',
			underscore = false,
		}: GetTrainingDataPayload) => {
			try {
				const response: GetTrainingDataResponse = await getData(
					'/get-training-data',
					await getAccessTokenSilently(),
					{ project_id: projId, crs: refSystem },
				)

				const formatFeatures =
					response.training_data?.map((item) => ({
						type: 'Feature' as const,
						geometry: item.geometry,
						properties: !underscore
							? {
									id: item.geometry_id,
									className: item.class_name,
									classColor: dataCategories?.find(
										(cat) => cat.className === item.class_name,
									)?.classColor,
							  }
							: {
									id: item.geometry_id,
									class_name: item.class_name,
									class_color: dataCategories?.find(
										(cat) => cat.className === item.class_name,
									)?.classColor,
							  },
					})) || []

				const localFeatureCollection = {
					type: 'FeatureCollection' as const,
					crs: { type: 'name', properties: { name: refSystem } },
					features: formatFeatures,
				}
				if (showNotification) {
					enqueueSnackbar('Training data received successfully!', {
						variant: 'success',
						preventDuplicate: true,
						autoHideDuration: 8000,
					})
				}
				return localFeatureCollection
			} catch (err) {
				enqueueSnackbar('Training data could not be received!', {
					variant: 'error',
					autoHideDuration: 8000,
				})
				console.error(err)
				return undefined
			}
		},
		[getAccessTokenSilently, dataCategories, enqueueSnackbar],
	)

	const addTrainingData = useCallback(
		async ({ geometry, className }: AddTrainingDataPayload) => {
			try {
				setIsLoading(true)
				const isWithinRegion = isWithin(
					{
						type: 'Feature',
						geometry,
						properties: {},
					},
					activeRegion as FeatureWithProps,
				)
				if (isWithinRegion) {
					await postData('/add-training-data', await getAccessTokenSilently(), {
						geometry,
						project_id: projectId,
						class_name: className,
					})
					const tData = await getTrainingData({ projId: projectId })
					setTrainingFeatureColl(tData as DrawFeatureCollWithProps)
				} else {
					enqueueSnackbar(
						'The geometry is not fully within the selected region!',
						{ variant: 'warning' },
					)
				}
			} finally {
				setIsLoading(false)
			}
		},
		[setIsLoading, getAccessTokenSilently, projectId, getTrainingData],
	)

	const handleDeleteAllTrainingData = useCallback(async () => {
		try {
			setIsLoading(true)
			await postData(
				'/delete-all-training-data',
				await getAccessTokenSilently(),
				{
					project_id: projectId,
				},
			)
			const tData = await getTrainingData({ projId: projectId })
			setTrainingFeatureColl(tData as DrawFeatureCollWithProps)
		} finally {
			setIsLoading(false)
		}
	}, [getAccessTokenSilently, getTrainingData, projectId, setIsLoading])

	const handleAddTrainingData = (
		feature: Feature<Polygon>,
		localDraw: Draw,
	) => {
		if (localDraw.title !== 'Delete') {
			void addTrainingData({
				className: localDraw.title,
				geometry: feature.geometry,
			})
		}
	}

	const handleRemoveTrainingData = useCallback(
		async ({ geometryId }: RemoveTrainingDataPayload) => {
			try {
				setIsLoading(true)

				await postData(
					'/delete-training-data',
					await getAccessTokenSilently(),
					{
						geometry_id: geometryId,
						project_id: projectId,
					},
				)
				const tData = await getTrainingData({
					projId: projectId,
					showNotification: false,
				})
				setTrainingFeatureColl(tData as DrawFeatureCollWithProps)
				setSelectedDrawFeature(undefined)
				enqueueSnackbar('Training data deleted successfully!', {
					variant: 'success',
					preventDuplicate: true,
					autoHideDuration: 8000,
				})
			} finally {
				setIsLoading(false)
			}
		},
		[
			setIsLoading,
			getAccessTokenSilently,
			projectId,
			getTrainingData,
			enqueueSnackbar,
		],
	)

	const handleDownloadZipFile = useCallback(
		async ({ refSystem }: DownloadZipPayload) => {
			try {
				if (!currentProject || !activeRegion) {
					return
				}
				setIsLoading(true)
				const tData = await getTrainingData({
					projId: projectId,
					showNotification: false,
					underscore: true,
					refSystem,
				})
				const name = `${
					activeRegion?.properties.name || ''
				}_${currentProject?.acquisition_date.substring(0, 10)}_training-data`
				if (!tData) throw Error('No data')

				const zip = new JSZip()
				const jsonString = JSON.stringify(tData, undefined, 2)

				const blob = new Blob([jsonString], {
					type: 'application/json;charset=utf-8',
				})
				zip.file(`${name}.geojson`, blob)
				await zip
					.generateAsync({ type: 'blob' })
					.then((content) => saveAs(content, `${name}.zip`))
			} catch (err) {
				enqueueSnackbar('Training data could not be downloaded!', {
					variant: 'error',
					preventDuplicate: true,
					autoHideDuration: 8000,
				})
			} finally {
				setIsLoading(false)
			}
		},
		[enqueueSnackbar, getTrainingData, projectId, setIsLoading],
	)

	const handleGetPixelCount = useCallback(async () => {
		try {
			setIsLoading(true)
			const response = await getData(
				`/pixel-count?project_id=${projectId}&product_id=${Number(
					currentProject?.last_product?.product_id,
				)}`,
				await getAccessTokenSilently(),
			)
			setPixelCount(response.categories_area)
		} finally {
			setIsLoading(false)
		}
	}, [
		currentProject,
		getAccessTokenSilently,
		setIsLoading,
		projectId,
		setPixelCount,
	])

	const getDataForProject = useCallback(async () => {
		const tData = await getTrainingData({
			projId: projectId,
		})

		setTrainingFeatureColl(tData as DrawFeatureCollWithProps)
	}, [getTrainingData, projectId, setTrainingFeatureColl])

	useEffect(() => {
		if (currentProject && activeRegion) {
			void getDataForProject()
		}
	}, [currentProject, activeRegion, getDataForProject])

	useEffect(() => {
		if (currentProject?.active_classes) {
			const activeClassesMap = currentProject?.active_classes.map(
				(classObj) => ({
					className: classObj.class_name,
					classColor: classObj.class_color,
					classId: classObj.class_id,
				}),
			)
			setDataCategories(activeClassesMap)
		}
	}, [currentProject])

	const hasProduct = useMemo(
		() => Boolean(currentProject?.last_product),
		[currentProject],
	)

	const handleUploadZipFile = useCallback(
		async ({ files, route }: UploadZipPayload) => {
			try {
				setIsLoading(true)
				const formData = new FormData()
				formData.append('file', files[0])
				formData.append('project_id', projectId)
				const response = await postData(
					route,
					await getAccessTokenSilently(),
					formData,
					{
						'Content-Type': 'multipart/form-data',
					},
				)

				const currentResponseKey = responseKey[route]
				const formatFeaturesForState = response[currentResponseKey].map(
					(item: {
						class_name: string
						geometry_id: number
						geometry: { type: string; coordinates: [number, number] }
					}) => ({
						type: 'Feature',
						geometry: item.geometry,
						properties: {
							id: item.geometry_id,
							className: item.class_name,
							classColor: dataCategories?.find(
								(cat) => cat.className === item.class_name,
							)?.classColor,
						},
					}),
				)
				enqueueSnackbar('Data uploaded successfully!', {
					variant: 'success',
					autoHideDuration: 8000,
				})

				if (response.warnings.length > 0) {
					response.warnings.forEach((warning: string) =>
						enqueueSnackbar(warning, {
							variant: 'warning',
						}),
					)
				}

				return formatFeaturesForState
			} catch (err) {
				enqueueSnackbar('Training data could not be uploaded!', {
					variant: 'error',
					autoHideDuration: 8000,
				})
				return undefined
			} finally {
				setIsLoading(false)
			}
		},
		[
			getAccessTokenSilently,
			projectId,
			setIsLoading,
			dataCategories,
			enqueueSnackbar,
		],
	)
	const convertHexToRGB = (color: string): RGBAColor => {
		const aRgbHex = color.match(/.{1,2}/g)
		if (aRgbHex) {
			return [
				parseInt(aRgbHex[0], 16),
				parseInt(aRgbHex[1], 16),
				parseInt(aRgbHex[2], 16),
			] as [number, number, number]
		}

		return [11, 69, 102, 255]
	}
	const getFeatureClassColor = useCallback(
		(f: DrawFeatureWithProps) => {
			const hexColor = dataCategories?.find(
				(classObj) => classObj.className === f.properties.className,
			)
			if (hexColor) {
				const darkerHex = shadeBlend(
					-0.25,
					hexColor.classColor,
				) as unknown as string
				const noHashDarkerColor = String(darkerHex.split('#')[1])
				return convertHexToRGB(noHashDarkerColor)
			}
			return [11, 69, 102, 255] as RGBAColor
		},
		[dataCategories],
	)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const trainingDrawLayer = new (EditableGeoJsonLayer as any)({
		id: 'draw-geojson-layer',
		mode: draw?.mode || ViewMode,
		data: emptyCollection,
		selectedFeatureIndexes: [],
		onEdit: (data: EditAction<DrawFeatureCollWithProps>) => {
			if (data.editType === 'addFeature' && draw) {
				handleAddTrainingData(
					data.updatedData.features[data.updatedData.features.length - 1],
					draw,
				)
			}
		},
		_subLayerProps: {
			guides: {
				getFillColor: (f) => {
					if (
						f.properties.guideType === 'editHandle' &&
						f.geometry.type === 'Point'
					)
						return [251, 176, 59, 255]
					return [251, 176, 59, 100]
				},
				getLineColor: () => [251, 176, 59],
				getLineWidth: () => 2,
				pointType: 'circle',
				getPointRadius: 6,
				pointRadiusUnits: 'pixels',
			},
			geojson: {
				getFillColor: () => [60, 178, 208, 20],
				getLineColor: () => [60, 178, 208],
				getLineWidth: () => 2,
			},
		},
	})

	const trainingDataLayer = new GeoJsonLayer({
		id: 'training-data-layer',
		data: (showTrainingData && trainingFeatureColl) ?? undefined,
		stroked: true,
		filled: true,
		pickable: true,
		lineWidthMinPixels: 1,
		getLineWidth: (f: DrawFeatureWithProps) =>
			selectedDrawFeature?.properties.id === f.properties.id ? 4 : 2,
		lineWidthUnits: 'pixels',
		getLineColor: (f: DrawFeatureWithProps) => getFeatureClassColor(f),
		getFillColor: [0, 0, 0, 0],
		onClick: (item: PickInfo<DrawFeatureWithProps>) =>
			setSelectedDrawFeature(item.object),
	})

	useEffect(() => {
		if (hasProduct) {
			void handleGetPixelCount()
		}
	}, [hasProduct, handleGetPixelCount])

	useEffect(() => {
		if (trainingFeatureColl) {
			const buildDrawFeatureCall = {
				type: 'FeatureCollection' as const,
				crs: { type: 'name', properties: { name: 'EPSG:4326' } },
				features: trainingFeatureColl.features.map((feature) => ({
					...feature,
					properties: {
						...feature.properties,
						selected: false,
					},
				})),
			}
			setTrainingFeatureColl({ ...buildDrawFeatureCall })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		draw?.title,
		selectedDrawFeature,
		setTrainingFeatureColl,
		setSelectedDrawFeature,
	])
	return (
		<TrainingDataContext.Provider
			value={{
				state: {
					draw,
					trainingFeatureColl,
					selectedDrawFeature,
					dataCategories,
					pixelCount,
					showTrainingData,
				},
				actions: {
					setDraw,
					setSelectedDrawFeature,
					setDataCategories,
					setPixelCount,
					setTrainingFeatureColl,
					handleRemoveTrainingData,
					handleDeleteAllTrainingData,
					getFeatureClassColor,
					handleDownloadZipFile,
					handleUploadZipFile,
					setShowTrainingData,
					handleGetPixelCount,
				},
				layers: {
					trainingDrawLayer,
					trainingDataLayer,
				},
			}}
		>
			{children}
		</TrainingDataContext.Provider>
	)
}
export default TrainingDataContextProvider
