import React, { FC, ReactNode } from 'react'
import { Paper, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		zIndex: 20,
		// [theme.breakpoints.down('sm')]: {
		// 	width: '100%',
		// 	height: '50%',
		// },
	},
	paper: {
		overflowX: 'hidden',
		overflowY: 'auto',
		height: '100%',
		boxShadow: theme.shadows[4],
		// [theme.breakpoints.down('sm')]: {
		// 	width: '100%',
		// },
	},
	leftBorder: {
		borderLeft: `1px solid ${theme.palette.divider}`,
	},
	topBorder: {
		borderTop: `1px solid ${theme.palette.divider}`,
	},
}))

interface Props {
	children: ReactNode
	sidebarWidth: number
}
const SidebarContent: FC<Props> = ({ children, sidebarWidth }) => {
	const classes = useStyles()

	return (
		<Box height={1} className={classes.root}>
			<Paper
				style={{ width: `${String(sidebarWidth)}px` }}
				className={classes.paper}
			>
				{children}
			</Paper>
		</Box>
	)
}

export default SidebarContent
