import React, { FC, useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import ViewProjectScreen from 'view-project/ViewProjectScreen'
import Navigation from 'sav-features/navigation/Navigation'
import ErrorScreen from 'sav-features/error-screen/ErrorScreen'
import { useAuth0 } from '@auth0/auth0-react'
import DashboardScreen from 'dashboard/DashboardScreen'
import CreateProjectScreen from 'create-project/CreateProjectScreen'
import { DarkModeContext } from 'sav-features/dark-mode/DarkModeContextProvider'
import { PaletteType } from '@material-ui/core'

const AppScreen: FC = () => {
	const { user, logout } = useAuth0()
	const {
		actions: { setIsDarkMode },
	} = useContext(DarkModeContext)
	return (
		<>
			{user && (
				<Navigation
					position="relative"
					user={user}
					logout={logout}
					description="Vegetation mapping in shallow marine areas of the Swedish coast"
					setDarkMode={(v: PaletteType) => setIsDarkMode(v === 'dark')}
					hasDarkMode
				/>
			)}
			<Switch>
				<Route path="/" exact render={() => <DashboardScreen />} />
				<Route path="/new-project" render={() => <CreateProjectScreen />} />
				<Route
					path="/project/:id"
					render={({ match }) => (
						<ViewProjectScreen projectId={match.params.id} />
					)}
				/>
				<Route
					path="*"
					render={() => (
						<ErrorScreen
							error="404"
							message="Sorry, we couldn't find this page"
							type="http"
						/>
					)}
				/>
			</Switch>
		</>
	)
}
export default AppScreen
