import React, {
	FC,
	ReactNode,
	createContext,
	useState,
	useEffect,
	useCallback,
} from 'react'
import { TileLayer } from '@deck.gl/geo-layers'
import { TileLayerProps } from '@deck.gl/geo-layers/tile-layer/tile-layer'
import bbox from '@turf/bbox'
import moment from 'moment'
import { BitmapLayer } from '@deck.gl/layers'
import { ProjectType } from '../app-screen/types'
import useRasterLayer from '../map/hooks/useRasterLayer'
import { BandStatisticsRanges, PreProcessSliderRanges } from './types'

interface PreprocessContextValues {
	state: {
		preProcessRanges: PreProcessSliderRanges | undefined
		activePreRangesStat: string | undefined
	}
	actions: {
		setPreProcessRanges: (p: PreProcessSliderRanges | undefined) => void
		setActivePreRangesStat: (p: string | undefined) => void
	}
	layers: {
		preprocessLayer: TileLayer<unknown, TileLayerProps<unknown>> | undefined
		extentPreprocessLayer:
			| TileLayer<unknown, TileLayerProps<unknown>>[]
			| undefined
	}
}

interface Props {
	children: ReactNode
	currentProject: ProjectType | undefined
	isExtent?: boolean
}

type Context = PreprocessContextValues
export const PreprocessContext = createContext<Context>(
	null as unknown as Context,
)

const TERRACOTTA_URL = process.env.REACT_APP_TERACCOTTA_URL

const PreprocessContextProvider: FC<Props> = ({
	children,
	currentProject,
	isExtent,
}) => {
	const [preProcessRanges, setPreProcessRanges] = useState<
		PreProcessSliderRanges | undefined
	>(undefined)
	const [activePreRangesStat, setActivePreRangesStat] = useState<
		string | undefined
	>(undefined)
	const [extentPCDataLayers, setExtentPCDataLayers] = useState<
		string[] | undefined
	>(undefined)

	const bboxOfRegion =
		currentProject &&
		bbox({
			type: 'Feature',
			geometry: currentProject.region_geometry,
		})

	useEffect(() => {
		if (currentProject?.band_statistics) {
			const bandStatisticsKeys = Object.keys(currentProject.band_statistics)
			const bandStatisticsDefault = bandStatisticsKeys.find(
				(item) => item === 'default',
			)
			setPreProcessRanges(currentProject.band_statistics)
			if (!activePreRangesStat)
				if (bandStatisticsDefault) setActivePreRangesStat(bandStatisticsDefault)
				else setActivePreRangesStat(bandStatisticsKeys[0])
		}
	}, [
		currentProject?.band_statistics,
		currentProject?.active_classes,
		activePreRangesStat,
	])

	const showRGBTile = useCallback(
		(ranges: BandStatisticsRanges) => {
			if (currentProject && ranges.red && ranges.green && ranges.blue) {
				const rRange = [ranges.red.low, ranges.red.high]
				const gRange = [ranges.green.low, ranges.green.high]
				const bRange = [ranges.blue.low, ranges.blue.high]

				const rRangeQueryparam = encodeURI(`[${rRange[0]},${rRange[1]}]`)
				const gRangeQueryparam = encodeURI(`[${gRange[0]},${gRange[1]}]`)
				const bRangeQueryparam = encodeURI(`[${bRange[0]},${bRange[1]}]`)

				return (
					`${String(TERRACOTTA_URL)}/rgb/${String(
						currentProject.project_deployment,
					)}/preprocessed/${String(
						currentProject?.id,
					)}/null/null/{z}/{x}/{y}.png?r=B04&g=B03&b=B02` +
					`&r_range=${rRangeQueryparam}&g_range=${gRangeQueryparam}&b_range=${bRangeQueryparam}`
				)
			}
			return undefined
		},
		[currentProject],
	)

	const savRasterLayer = useRasterLayer({
		id: 'preprocess-terracotta-layer',
		data:
			currentProject &&
			preProcessRanges &&
			activePreRangesStat &&
			showRGBTile(preProcessRanges[activePreRangesStat]),
	})

	const extentDataLayers = async () => {
		if (currentProject) {
			const onlyDate = currentProject.acquisition_date.substr(0, 10)
			const dateWithExtraDayArray = new Date(onlyDate)
			dateWithExtraDayArray.setDate(dateWithExtraDayArray.getDate() + 1)

			const formatExtraDay = moment(dateWithExtraDayArray).format('YYYY-MM-DD')

			const response = await fetch(
				`https://planetarycomputer.microsoft.com/api/stac/v1/search?
					collections=sentinel-2-l2a
					&bbox=${String(bboxOfRegion)}
					&datetime=${onlyDate}/${formatExtraDay}
					&limit=1000`,
			).then((res) => res.json())

			const tilesPromises = response.features.map(({ assets }) =>
				fetch(assets.tilejson.href)
					.then((res) => res.json())
					.then((res) => res.tiles),
			)
			const tilesUrls: string[] = await Promise.all(tilesPromises)
			setExtentPCDataLayers(tilesUrls.flat())
		}

		return undefined
	}

	useEffect(() => {
		if (isExtent && currentProject) {
			void extentDataLayers()
		}
	}, [isExtent, currentProject])

	const preRanges =
		activePreRangesStat && preProcessRanges?.[activePreRangesStat]

	const extentPreprocessLayer =
		(extentPCDataLayers &&
			extentPCDataLayers.map(
				(url, i) =>
					new TileLayer({
						id: `preprocess-terracotta-layer-${i}`,
						data:
							preRanges &&
							`${url}&rescale=${preRanges.red.low},${preRanges.red.high}&rescale=${preRanges.green.low},${preRanges.green.high}&rescale=${preRanges.blue.low},${preRanges.blue.high}`,
						minZoom: 0,
						pickable: true,
						maxZoom: 19,
						tileSize: 256,
						renderSubLayers: (props) => {
							const {
								bbox: { west, south, east, north },
							} = props.tile

							return new BitmapLayer(props, {
								data: null,
								image: props.data,
								bounds: [west, south, east, north],
							})
						},
					}) as TileLayer<unknown, TileLayerProps<unknown>>,
			)) ||
		undefined

	const preprocessLayer = savRasterLayer

	return (
		<PreprocessContext.Provider
			value={{
				state: {
					preProcessRanges,
					activePreRangesStat,
				},
				actions: {
					setPreProcessRanges,
					setActivePreRangesStat,
				},
				layers: { preprocessLayer, extentPreprocessLayer },
			}}
		>
			{children}
		</PreprocessContext.Provider>
	)
}
export default PreprocessContextProvider
