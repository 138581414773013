import React, { FC } from 'react'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
	iconButton: {
		'&:hover': {
			backgroundColor: 'unset',
		},
	},
}))

interface Props {
	// eslint-disable-next-line react/require-default-props
	checked?: boolean
}
const CustomCheckbox: FC<Props> = ({ checked = true }) => {
	const classes = useStyles()
	return checked ? (
		<IconButton className={classes.iconButton}>
			<KeyboardArrowUp />
		</IconButton>
	) : (
		<IconButton className={classes.iconButton}>
			<KeyboardArrowDown />
		</IconButton>
	)
}

export default CustomCheckbox
