import React, { FC, useEffect, useState, useCallback, ReactNode } from 'react'
import { Box } from '@material-ui/core'
import { ResizeHandle } from '@dhi/react-components-lab'

interface Props {
	slotLeft: ReactNode
	slotRight: ReactNode
	sidebarWidth: number
	handleSidebarWidth: (v: number) => void
}

const VerticalResizeHandler: FC<Props> = ({
	slotLeft,
	slotRight,
	sidebarWidth,
	handleSidebarWidth,
}) => {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth)

	const handleResize = useCallback(() => {
		setScreenWidth(window.innerWidth)
	}, [])

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [screenWidth, handleResize])

	return (
		<Box
			display="flex"
			flexDirection="row"
			height={1}
			width={1}
			p={0}
			m={0}
			position="relative"
		>
			<Box display="flex" width={1} height="calc(100vh - 60px)">
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					width={`calc(100vw - ${String(sidebarWidth)}px )`}
				>
					{slotLeft}
				</Box>
				<Box>
					<Box position="relative" style={{ zIndex: 21 }}>
						<Box
							position="absolute"
							display="flex"
							left={-16}
							justifyContent="center"
							height="calc(100vh - 60px)"
						>
							<ResizeHandle
								vertical
								onDrag={handleSidebarWidth}
								wrapperSize={screenWidth}
								draggableSize={sidebarWidth}
								minDraggableSize={300}
								minContainerSize={300}
							/>
						</Box>
					</Box>
					<Box
						height={1}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						{slotRight}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default VerticalResizeHandler
