import React, { FC, useContext, useEffect, useMemo } from 'react'
import { Box, Button, Slider, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MapContext } from 'sav-features/map/MapContextProvider'
import Map from 'sav-features/map/Map'
import getViewportForFeature from 'sav-features/map/utils/getViewportForFeature'
import { Popup, AttributionControl } from 'react-map-gl'
import centerOfMass from '@turf/center-of-mass'
import { IMikeTheme } from '@dhi/react-components-lab'
import { GeoJsonLayer } from 'deck.gl'
import { SidebarContext } from 'sav-features/sidebar/SidebarContextProvider'
import { DrawFeatureWithProps } from 'sav-features/training-data/types'
import { FeasyContext } from 'sav-features/feasibility-mask/FeasyContextProvider'
import { ValidationContext } from 'sav-features/validation/ValidationContextProvider'
import { TrainingDataContext } from 'sav-features/training-data/TrainingDataContextProvider'
import { RegionsContext } from 'sav-features/regions/RegionsContextProvider'
import { HabitatLayerContext } from 'sav-features/habitat-layer/HabitatLayerContextProvider'
import { PreprocessContext } from 'sav-features/preprocess/PreprocessContextProvider'
import { OrthoContext } from 'sav-features/orthophoto/OrthoContextProvider'
import { Feature } from 'geojson'

import { BrightnessContext } from 'sav-features/brightness/BrightnessContextProvider'
import LayersPanelView from './LayersPanelView'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	confirmDeleteButton: {
		backgroundColor: theme.palette.error?.main,
		color: theme.palette.background.paper,
		'&:hover': {
			backgroundColor: theme.palette.error?.dark,
		},
	},
}))

// eslint-disable-next-line radar/cognitive-complexity
const MapFeatures: FC = () => {
	const {
		state: { width },
	} = useContext(SidebarContext)
	const {
		layers: { orthoLayers },
	} = useContext(OrthoContext)
	const {
		layers: { habitatLayer },
	} = useContext(HabitatLayerContext)
	const {
		effects: { brightnessEffect },
	} = useContext(BrightnessContext)

	const {
		state: { viewport, mapHeight, mapWidth },
		actions: { setViewport, handleViewport },
	} = useContext(MapContext)

	const {
		state: { showValidation, validationData },
	} = useContext(ValidationContext)

	const {
		layers: { preprocessLayer },
	} = useContext(PreprocessContext)

	const {
		state: { activeRegion },
		layers: { activeRegionLayer },
	} = useContext(RegionsContext)

	const {
		state: { draw, selectedDrawFeature },
		actions: { getFeatureClassColor },
		layers: { trainingDrawLayer, trainingDataLayer },
	} = useContext(TrainingDataContext)

	const {
		layers: { infeasyLayer, feasyLayer },
	} = useContext(FeasyContext)

	const theme = useTheme()

	const pointRadius = useMemo(() => {
		if (viewport.zoom !== undefined) {
			// calculate the result based on "exponential decay formula".
			// Change the "0.55" to change the curve of the exponential graph.
			return 50000 * 0.55 ** Number(viewport.zoom.toFixed(2))
		}

		return 100
	}, [viewport.zoom])

	const hasSizes = useMemo(
		() => Boolean(mapHeight !== undefined && mapWidth !== undefined),
		[mapWidth, mapHeight],
	)
	const hasRegion = useMemo(() => Boolean(activeRegion), [activeRegion])

	useEffect(() => {
		if (
			selectedDrawFeature &&
			mapWidth !== undefined &&
			mapHeight !== undefined
		) {
			const feat = selectedDrawFeature
			const localViewport = getViewportForFeature(
				feat as Feature,
				mapHeight,
				mapWidth,
			)

			const zoomLevel = localViewport.zoom - 1.5

			handleViewport({ ...localViewport, zoom: zoomLevel })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasSizes, handleViewport, selectedDrawFeature])

	useEffect(() => {
		if (activeRegion && mapWidth !== undefined && mapHeight !== undefined) {
			const feat = selectedDrawFeature || activeRegion
			const localViewport = getViewportForFeature(
				feat as Feature,
				mapHeight,
				mapWidth,
			)

			const zoomLevel = selectedDrawFeature
				? localViewport.zoom - 1.5
				: localViewport.zoom

			handleViewport({ ...localViewport, zoom: zoomLevel })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasSizes, hasRegion, activeRegion, handleViewport])

	const validationDataLayer = new GeoJsonLayer({
		id: 'validation-data-layer',
		data: (showValidation && validationData) ?? undefined,
		stroked: true,
		filled: true,
		pickable: true,
		lineWidthMinPixels: 1,
		getLineWidth: 2,
		lineWidthUnits: 'pixels',
		radiusUnits: 'pixels',
		getPointRadius: pointRadius,
		getLineColor: (f: DrawFeatureWithProps) => {
			if (f.geometry.type.toLowerCase() === 'point') {
				return [242, 245, 247, 255]
			}

			return getFeatureClassColor(f)
		},
		getFillColor: (f: DrawFeatureWithProps) => {
			if (f.geometry.type.toLowerCase() === 'point') {
				return getFeatureClassColor(f)
			}
			return [0, 0, 0, 0]
		},
	})

	return (
		<>
			<LayersPanelView />
			<Map
				getCursor={() =>
					draw && draw.title !== 'Delete' ? 'crosshair' : 'pointer'
				}
				view={viewport}
				setViewport={setViewport}
				layers={[
					preprocessLayer,
					...orthoLayers,
					activeRegionLayer,
					habitatLayer,
					infeasyLayer,
					feasyLayer,
					trainingDataLayer,
					trainingDrawLayer,
					validationDataLayer,
				]}
				effects={[brightnessEffect]}
				style={{ minWidth: '300px' }}
				width={`calc(100vw - ${String(width)}px )`}
				controller={{
					doubleClickZoom: Boolean(!draw),
				}}
				themeType={theme.palette.type}
				attrControl={
					<AttributionControl
						customAttribution="© Lantmäteriet/Metria."
						style={{ right: 0, bottom: 0 }}
					/>
				}
			/>
		</>
	)
}

export default MapFeatures
