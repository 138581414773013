import React, {
	FC,
	useState,
	useCallback,
	ReactNode,
	createContext,
	useContext,
} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useSnackbar } from 'notistack'
import getData from 'sav-utils/getData'
import { AppContext } from '../app-screen/AppContextProvider'
import { DateWithClouds, GetDatesPayload } from './types'
import { RegionsContext } from '../regions/RegionsContextProvider'

interface DateContextValues {
	state: {
		dates: DateWithClouds[] | undefined
		activeDate: string | undefined
		isInvalidDate: boolean
		datePreviewImage: string | undefined
		isDatePreviewLoading: boolean
	}
	actions: {
		setDates: (r: DateWithClouds[] | undefined) => void
		setActiveDate: (r: string | undefined) => void
		handleGetDates: (pay: GetDatesPayload) => Promise<void>
		setIsInvalidDate: (r: boolean) => void
		setDatePreviewImage: (v: string | undefined) => void
		setIsDatePreviewLoading: (v: boolean) => void
		handleGetPreview: (d: string) => Promise<void>
	}
}

interface Props {
	children: ReactNode
}

type Context = DateContextValues
export const DateContext = createContext<Context>(null as unknown as Context)

const DateContextProvider: FC<Props> = ({ children }) => {
	const { getAccessTokenSilently } = useAuth0()
	const { enqueueSnackbar } = useSnackbar()

	const {
		actions: { setIsLoading },
	} = useContext(AppContext)
	const {
		state: { activeRegion },
	} = useContext(RegionsContext)

	const [dates, setDates] = useState<DateWithClouds[] | undefined>(undefined)
	const [activeDate, setActiveDate] = useState<string | undefined>(undefined)
	const [isInvalidDate, setIsInvalidDate] = useState<boolean>(false)
	const [datePreviewImage, setDatePreviewImage] = useState<string | undefined>(
		undefined,
	)
	const [isDatePreviewLoading, setIsDatePreviewLoading] =
		useState<boolean>(false)

	const handleGetDates = useCallback(
		async ({ regionId }: GetDatesPayload) => {
			setIsLoading(true)
			try {
				const response = await getData(
					'/get-scene-dates',
					await getAccessTokenSilently(),
					{ region_id: regionId },
				)
				setDates(response.data)
			} catch (err) {
				enqueueSnackbar(`There was a problem when retrieving the dates.`, {
					variant: 'error',
					autoHideDuration: 8000,
				})
			} finally {
				setIsLoading(false)
			}
		},
		[setIsLoading, getAccessTokenSilently, enqueueSnackbar],
	)

	const handleGetPreview = useCallback(
		async (date: string) => {
			try {
				setIsDatePreviewLoading(true)

				const response = await getData(
					'/get-thumbnail',
					await getAccessTokenSilently(),
					{
						date,
						region_id: activeRegion?.properties.regionId,
					},
					{ responseType: 'blob' },
				)
				setIsInvalidDate(false)
				setDatePreviewImage(URL.createObjectURL(response))
			} catch (err) {
				enqueueSnackbar(
					`There is a Sentinel-2 tile archived for this date but it contains no data for this region.`,
					{
						variant: 'error',
						autoHideDuration: 8000,
					},
				)
				setDatePreviewImage(undefined)
				setIsInvalidDate(true)
			} finally {
				setIsDatePreviewLoading(false)
			}
		},
		[
			activeRegion?.properties.regionId,
			enqueueSnackbar,
			getAccessTokenSilently,
		],
	)

	return (
		<DateContext.Provider
			value={{
				state: {
					dates,
					activeDate,
					isInvalidDate,
					datePreviewImage,
					isDatePreviewLoading,
				},
				actions: {
					setDates,
					setActiveDate,
					handleGetDates,
					setIsInvalidDate,
					setDatePreviewImage,
					setIsDatePreviewLoading,
					handleGetPreview,
				},
			}}
		>
			{children}
		</DateContext.Provider>
	)
}
export default DateContextProvider
