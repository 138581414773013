import React, { FC, useMemo, useState } from 'react'
import { Box, Typography, useTheme, makeStyles } from '@material-ui/core'
import colormap from 'colormap'

const useStyles = makeStyles((theme) => ({
	boxItem: {
		backgroundColor: theme.palette.grey[50],
		flexGrow: 1,
		border: `0.5px solid ${theme.palette.divider}`,
	},
	boxItemText: {
		fontWeight: 400,
		color: theme.palette.grey[50],
	},
}))
interface Props {
	width: number
	height: number
	numberOfClasses: number
	percentageValues: number[][]
	displayValue: number
	positionI: number
	positionJ: number
}
const ConfusionMatrixBox: FC<Props> = ({
	percentageValues,
	displayValue,
	width,
	height,
	numberOfClasses,
	positionI,
	positionJ,
}) => {
	const theme = useTheme()
	const classes = useStyles()
	const colors = useMemo(
		() =>
			colormap({
				colormap: 'greens',
				nshades: 100,
				format: `hex`,
				alpha: 1,
			}).reverse(),
		[],
	)

	const boxValue = percentageValues[positionI][positionJ]
	const isBoxAClass =
		positionI + 1 <= numberOfClasses && positionJ + 1 <= numberOfClasses
	const isTotalRowCol =
		positionI <= numberOfClasses && positionJ <= numberOfClasses
	const colorIndex =
		positionI === positionJ && isBoxAClass
			? Number(boxValue.toFixed(2).split('.').join('')) - 1
			: 0
	const bgColor = isBoxAClass ? colors[colorIndex] : undefined

	const textShadowPixelSize = (((100 - (colorIndex + 1)) * 1.1) / 100).toFixed(
		2,
	)
	const textShadow = isBoxAClass
		? `${textShadowPixelSize}px 0 #557A8F,
			0 ${textShadowPixelSize}px #557A8F,
			-${textShadowPixelSize}px 0 #557A8F,
			0 -${textShadowPixelSize}px #557A8F`
		: undefined
	const greyValueOnDarkMode = theme.palette.type === 'dark' ? 900 : 50
	const showValue =
		isBoxAClass || isTotalRowCol ? displayValue : displayValue.toFixed(2)

	return width !== undefined ? (
		<Box
			className={classes.boxItem}
			style={{
				backgroundColor: bgColor,
			}}
			width={width}
			height={height}
			display="flex"
			alignItems="center"
			justifyContent="center"
		>
			<Typography
				variant="body2"
				className={classes.boxItemText}
				style={{
					textShadow,
					color: isBoxAClass
						? theme.palette.grey[greyValueOnDarkMode]
						: theme.palette.primary.main,
				}}
			>
				{showValue}
			</Typography>
		</Box>
	) : null
}

export default ConfusionMatrixBox
