import React, { FC } from 'react'
import DashboardContextProvider from './DashboardContextProvider'
import Dashboard from './Dashboard'

const DashboardScreen: FC = () => (
	<DashboardContextProvider>
		<Dashboard />
	</DashboardContextProvider>
)

export default DashboardScreen
