import React, { FC, useContext } from 'react'
import { Box, Typography, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { IMikeTheme } from '@dhi/react-components-lab'
import Slider from 'sav-components/Slider'
import { useSnackbar } from 'notistack'
import {
	VisibilityOffOutlined as ToggleOffIcon,
	VisibilityOutlined as ToggleOnIcon,
} from '@material-ui/icons'
import { useTitleStyles } from '../create-project/styles'
import { ProductType } from '../app-screen/types'
import { HabitatLayerContext } from './HabitatLayerContextProvider'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	icon: {
		width: 18,
		height: 18,
		color: theme.palette.primary.main,
	},
}))

interface Props {
	currentProduct: ProductType | undefined
}
const HabitatLayerControl: FC<Props> = ({ currentProduct }) => {
	const {
		state: { showHabitatLayer },
		actions: { setHabitatLayerOpacity, setShowHabitatLayer },
	} = useContext(HabitatLayerContext)
	const { enqueueSnackbar } = useSnackbar()
	const classes = useStyles()
	const titleStyles = useTitleStyles()

	const handleHabitatLayer = (val: boolean) =>
		currentProduct
			? setShowHabitatLayer(val)
			: enqueueSnackbar(
					'There is no classification available for this project.',
					{
						variant: 'info',
						autoHideDuration: 8000,
					},
			  )
	return (
		<>
			<Box
				mb={1}
				display="flex"
				alignItems="center"
				justifyContent="space-between"
			>
				<Typography variant="body2" className={titleStyles.subtitle}>
					Habitat classification
				</Typography>
				<Box ml={1}>
					<IconButton
						size="small"
						onClick={() => handleHabitatLayer(!showHabitatLayer)}
					>
						{currentProduct?.status === 'COMPLETED' && showHabitatLayer ? (
							<ToggleOnIcon className={classes.icon} />
						) : (
							<ToggleOffIcon className={classes.icon} />
						)}
					</IconButton>
				</Box>
			</Box>
			<Box position="relative" display="flex" height={45} alignItems="center">
				<Slider
					defaultValue={100}
					title="Opacity: "
					min={0}
					max={100}
					step={1}
					unit="%"
					getValueCommitted={(val) => setHabitatLayerOpacity(Number(val))}
				/>
			</Box>
		</>
	)
}

export default HabitatLayerControl
