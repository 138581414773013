import React, { FC } from 'react'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { Public as PublicIcon } from '@material-ui/icons'
import { IconButton, Grid, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ViewportProps, FlyToInterpolator } from 'react-map-gl'
import { easeCubicInOut } from 'd3-ease'
import { MapContext } from './MapContextProvider'

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		left: 15,
		top: 120,
		width: 36,
		zIndex: 100,
		transform: 'translate(0, -50%)',
	},
	wrapper: {
		boxShadow: theme.shadows[4],
	},
	icon: {
		backgroundColor: theme.palette.background.paper,
		height: 36,
		width: 36,
		padding: 6,
		boxSizing: 'border-box',
	},
	activeIcon: {
		backgroundColor: theme.palette.primary.main,
		'& path': {
			fill: theme.palette.background.paper,
		},
		height: 36,
		width: 36,
		padding: 6,
		boxSizing: 'border-box',
	},
	iconButton: {
		padding: 'unset',
	},
}))

const ZoomControl: FC = () => {
	const classes = useStyles()
	const {
		state: { isOpticalBasemap, viewport },
		actions: { setViewport, setIsOpticalBasemap },
	} = React.useContext(MapContext)

	const handleViewport = (localViewport: ViewportProps) =>
		setViewport({
			...viewport,
			...localViewport,
			transitionInterpolator: new FlyToInterpolator(),
			transitionDuration: 2000,
			transitionEasing: easeCubicInOut,
		})

	return (
		<Grid container className={classes.root}>
			<Grid container className={classes.wrapper}>
				<Grid container>
					<IconButton
						onClick={() =>
							handleViewport({ ...viewport, zoom: Number(viewport.zoom) + 1 })
						}
						className={classes.iconButton}
					>
						<AddIcon className={classes.icon} />
					</IconButton>
				</Grid>
				<Grid container>
					<IconButton
						onClick={() =>
							handleViewport({ ...viewport, zoom: Number(viewport.zoom) - 1 })
						}
						className={classes.iconButton}
					>
						<RemoveIcon className={classes.icon} />
					</IconButton>
				</Grid>
				<Grid container>
					<Tooltip placement="right" title="Change base map">
						<IconButton
							onClick={() => setIsOpticalBasemap(!isOpticalBasemap)}
							className={classes.iconButton}
						>
							<PublicIcon
								className={
									!isOpticalBasemap ? classes.icon : classes.activeIcon
								}
							/>
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ZoomControl
