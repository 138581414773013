import React, { FC } from 'react'
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core'
import { useTitleStyles } from '../create-project/styles'

interface Props {
	datePreviewImage: string | string[] | undefined
	isLoading: boolean
}
const DatePreviewImage: FC<Props> = ({ isLoading, datePreviewImage }) => {
	const classes = useTitleStyles()

	if (isLoading)
		return (
			<Box mt={1}>
				<Grid container alignItems="center" justify="center">
					<CircularProgress />
				</Grid>
			</Box>
		)
	return datePreviewImage ? (
		<Box mt={1}>
			<Typography className={classes.subtitle} variant="body2" gutterBottom>
				Preview:
			</Typography>
			<Box p={1} maxHeight={200} overflow="auto">
				{Array.isArray(datePreviewImage) ? (
					datePreviewImage.map((imgUrl, i) => (
						<img
							alt="map-thumbnail"
							key={`image-${i}`}
							style={{ width: '100%', height: 'auto' }}
							src={imgUrl}
						/>
					))
				) : (
					<img
						alt="map-thumbnail"
						style={{ width: '100%', height: 'auto' }}
						src={datePreviewImage}
					/>
				)}
			</Box>
		</Box>
	) : null
}

export default DatePreviewImage
