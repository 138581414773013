// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/require-default-props */
import React, { FC, ReactElement, ReactNode, useContext } from 'react'
import DeckGL from '@deck.gl/react'
import { Box } from '@material-ui/core'
import {
	StaticMap,
	ViewportProps,
	_MapContext as MapContextMapGl,
} from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { MapContext } from './MapContextProvider'
import ZoomControl from './ZoomControl'

const accessToken =
	'pk.eyJ1Ijoiam9zbGRoaSIsImEiOiJja2d0ZjdzbXAwMXdxMnNwN2Jkb2NvbXJ3In0.SayFfMYF2huWsZckbqNqEw'

interface Props extends Partial<any> {
	view: ViewportProps
	children?: ReactNode
	mapStyle?: string | undefined
	setViewport: (v: ViewportProps) => void
	themeType?: 'light' | 'dark'
	attrControl?: ReactElement | undefined
}

type ResizeObj = {
	width: number
	height: number
}

const mapStylesTheme = {
	dark: 'mapbox://styles/mapbox/dark-v10',
	light: 'mapbox://styles/mapbox/light-v10',
}

const Map: FC<Props> = ({
	view,
	children = null,
	setViewport,
	mapStyle,
	themeType = 'light',
	attrControl,
	...otherProps
}) => {
	const {
		state: { isOpticalBasemap },
		actions: { setMapWidth, setMapHeight },
	} = useContext(MapContext)

	const handleResize = (width: number, height: number) => {
		setMapWidth(width)
		setMapHeight(height)
	}

	const basemap = isOpticalBasemap
		? 'mapbox://styles/mapbox/satellite-v9'
		: mapStylesTheme[themeType]

	return (
		<DeckGL
			ContextProvider={MapContextMapGl.Provider}
			initialViewState={view}
			width="100%"
			height="100%"
			controller
			onViewStateChange={(arg) => setViewport(arg.viewState)}
			onResize={(obj: ResizeObj) => handleResize(obj.width, obj.height)}
			{...otherProps}
		>
			<Box position="absolute" style={{ transform: 'translateX(-50%)' }}>
				<ZoomControl />
			</Box>
			<StaticMap
				width="100%"
				height="100%"
				mapboxApiAccessToken={accessToken}
				mapStyle={mapStyle || basemap}
				attributionControl={false}
			>
				{attrControl}
			</StaticMap>
			{children}
		</DeckGL>
	)
}

export default Map
