import React, { useContext, useMemo } from 'react'
import { HashRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import ErrorScreen from 'sav-features/error-screen/ErrorScreen'
import AppScreenWrapper from 'sav-features/app-screen/AppScreenWrapper'
import ThemeProvider from 'sav-features/ThemeProvider/ThemeProvider'
import AppScreen from 'app-screen/AppScreen'
import { DarkModeContext } from 'sav-features/dark-mode/DarkModeContextProvider'
import { PortalName } from 'sav-features/app-screen/AppContextProvider'

const AppThemeWrapper = () => {
	const {
		state: { isDarkMode },
	} = useContext(DarkModeContext)

	const themeType = useMemo(() => (isDarkMode ? 'dark' : 'light'), [isDarkMode])
	return (
		<ThemeProvider type={themeType}>
			<HashRouter>
				<Sentry.ErrorBoundary
					fallback={
						<ErrorScreen
							error="Error"
							message="Sorry, there has been an error"
							type="error"
						/>
					}
					showDialog
				>
					<AppScreenWrapper portalName={PortalName.Sweden}>
						<AppScreen />
					</AppScreenWrapper>
				</Sentry.ErrorBoundary>
			</HashRouter>
		</ThemeProvider>
	)
}

export default AppThemeWrapper
