// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/no-array-index-key */
import React, { FC, useState, useRef, useEffect, MutableRefObject } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { IMikeTheme } from '@dhi/react-components-lab'
import { ConfusionMatrixDanishType } from './types'
import ConfusionMatrixBox from './ConfusionMatrixBox'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	boxItem: {
		backgroundColor: theme.palette.grey[50],
		flexGrow: 1,
	},
	rowBox: {
		width: '100%',
		display: 'flex',
		flexWrap: 'nowrap',
		justifyContent: 'center',
	},
	boxItemText: {
		fontWeight: 500,
		color: theme.palette.grey[50],
	},
	axisLabel: {
		fontSize: 10,
		fontWeight: 600,
	},
	rotate45: {
		transform: 'rotate(45deg)',
	},
	rotateMinus45: {
		transform: 'rotate(-45deg)',
	},
	actualClassLabel: {
		transform: 'rotate(-90deg)',
		height: 'fit-content',
		whiteSpace: 'nowrap',
		fontSize: 10,
		fontWeight: 400,
	},
	predictedClassLabel: {
		whiteSpace: 'nowrap',
		fontSize: 10,
		fontWeight: 400,
	},
}))

interface Props {
	data: ConfusionMatrixDanishType
}

const ConfusionMatrix: FC<Props> = ({ data }) => {
	const [width, setWidth] = useState<number>(0)
	const classes = useStyles()
	const rowRef: MutableRefObject<null | HTMLDivElement> = useRef(null)

	useEffect(() => {
		if (rowRef?.current?.offsetWidth) {
			const widthSplitValue =
				Number(rowRef?.current?.offsetWidth) / data.labels.length
			setWidth(widthSplitValue)
		}
	}, [rowRef, data])

	return (
		<Box>
			<Box width={1} my={1} display="flex" justifyContent="center">
				<Box
					borderRadius={4}
					display="flex"
					justifyContent="flex-end"
					alignItems="flex-end"
					boxShadow={2}
					p={0.5}
					width={1}
				>
					<Box display="flex" flexWrap="nowrap" alignItems="center">
						<Box width={20}>
							<Typography variant="body2" className={classes.actualClassLabel}>
								Actual class
							</Typography>
						</Box>
						<Box
							display="flex"
							height="fit-content"
							flexDirection="column"
							alignItems="center"
							justifyContent="flex-end"
						>
							{data.labels.map((label: string, i: number) => (
								<Box
									key={`y-axis-label-${i}`}
									height={width}
									width="fit-content"
									overflow="auto"
									display="flex"
									alignItems="center"
									justifyContent="center"
									mx={0.5}
								>
									<Typography variant="body2" className={classes.axisLabel}>
										{label}
									</Typography>
								</Box>
							))}
						</Box>
					</Box>
					<Box width={1}>
						<Box width={1}>
							<Box width={1} display="flex" justifyContent="center">
								<Typography
									variant="body2"
									className={classes.predictedClassLabel}
								>
									Predicted class
								</Typography>
							</Box>
							<Box
								display="flex"
								width={1}
								alignItems="center"
								justifyContent="center"
							>
								{data.labels.map((label: string, i: number) => (
									<Box
										key={`x-axis-label-${i}`}
										width={width}
										overflow="auto"
										display="flex"
										alignItems="center"
										justifyContent="center"
										flexGrow={1}
										my={0.5}
									>
										<Typography variant="body2" className={classes.axisLabel}>
											{label}
										</Typography>
									</Box>
								))}
							</Box>
						</Box>
						<Box
							width={1}
							display="flex"
							justifyContent="center"
							flexDirection="column"
						>
							{data.values.map((item: number[], i: number) => (
								<div
									className={classes.rowBox}
									key={`number-row-${i}`}
									ref={rowRef}
								>
									{item.map((boxValue: number, j: number) => (
										<ConfusionMatrixBox
											displayValue={boxValue}
											numberOfClasses={data.labels.length}
											percentageValues={data.values}
											positionI={i}
											positionJ={j}
											width={width}
											height={width}
											key={`confusion-matrix-item-${i}-${j}`}
										/>
									))}
								</div>
							))}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default ConfusionMatrix
