import React, { FC, useContext, ReactNode } from 'react'
import { Box, Typography, IconButton } from '@material-ui/core'
import { CloseOutlined as CloseIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useTitleStyles } from '../create-project/styles'
import { LayersPanelContext } from './LayersPanelContextProvider'

const useStyles = makeStyles((theme) => ({
	containerHeader: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 15,
	},
	icon: {
		width: 18,
		height: 18,
		color: theme.palette.primary.main,
	},
	containerOptions: {
		padding: 15,
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
}))

interface Props {
	children: ReactNode
}

const MapLayers: FC<Props> = ({ children }) => {
	const {
		actions: { setOpenLayersPanel },
	} = useContext(LayersPanelContext)

	const titleStyles = useTitleStyles()

	const classes = useStyles()

	return (
		<Box width={1}>
			<Box>
				<Box className={classes.containerHeader}>
					<Typography variant="h3" className={titleStyles.subtitle}>
						Layers
					</Typography>
					<IconButton
						size="small"
						onClick={() => {
							setOpenLayersPanel(false)
						}}
					>
						<CloseIcon className={classes.icon} />
					</IconButton>
				</Box>
				{children}
			</Box>
		</Box>
	)
}

export default MapLayers
