import React, {
	FC,
	useContext,
	useMemo,
	useState,
	useCallback,
	useEffect,
} from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import Sidebar from 'sav-features/sidebar/Sidebar'
import { Card } from '@dhi/react-components-lab'
import SelectRegion from 'sav-features/regions/SidebarSelectRegion'
import SelectDate from 'sav-features/date-selection/SidebarSelectDate'
import PreProcessingSettings from 'sav-features/create-project/SidebarPreSettings'
import { useHistory } from 'react-router-dom'
import { AppContext } from 'sav-features/app-screen/AppContextProvider'
import { SidebarContext } from 'sav-features/sidebar/SidebarContextProvider'
import { RegionsContext } from 'sav-features/regions/RegionsContextProvider'
import CustomCheckbox from 'sav-features/create-project/CustomCheckbox'
import { DateContext } from 'sav-features/date-selection/DateContextProvider'
import { DataProductType } from 'sav-features/create-project/types'

const sidebarItems = [
	{ title: 'Select region', id: 1 },
	{ title: 'Select date', id: 2 },
	{
		title: 'Pre-processing',
		id: 3,
	},
]

const SidebarData: FC = () => {
	const {
		actions: { addProject },
	} = useContext(AppContext)

	const {
		state: { activeRegion, regions },
		actions: { setActiveRegion },
	} = useContext(RegionsContext)

	const {
		state: { width, activeSidebarItem },
		actions: { setActiveSidebarItem },
	} = useContext(SidebarContext)

	const {
		state: {
			activeDate,
			dates,
			isInvalidDate,
			datePreviewImage,
			isDatePreviewLoading,
		},
		actions: {
			handleGetDates,
			setActiveDate,
			handleGetPreview,
			setDatePreviewImage,
		},
	} = useContext(DateContext)

	const history = useHistory()

	const [wasRequestSuccess, setWasRequestSuccess] = useState<
		boolean | undefined
	>(undefined)
	const [deglint, setDeglint] = useState(false)
	const [cloudMask, setCloudMask] = useState(false)

	const hasRegions = useMemo(() => Boolean(activeRegion), [activeRegion])
	const hasActiveDate = useMemo(
		() => Boolean(activeRegion && activeDate && !isInvalidDate),
		[activeRegion, activeDate, isInvalidDate],
	)

	const isSidebarItemDisabled = useCallback(
		(value: number) => {
			switch (value + 1) {
				case 1:
					return false
				case 2:
					return !hasRegions
				case 3:
					return !(hasRegions && hasActiveDate)
				default:
					return false
			}
		},
		[hasRegions, hasActiveDate],
	)

	const handleCreateProject = async () => {
		if (wasRequestSuccess === undefined) {
			if (
				activeDate &&
				deglint !== undefined &&
				cloudMask !== undefined &&
				activeRegion
			) {
				const isSuccess = await addProject({
					date: activeDate,
					deglint,
					cloudMask,
					regionId: activeRegion.properties.regionId,
				})
				setWasRequestSuccess(isSuccess)
			}
		} else {
			history.push('/')
		}
	}
	const buttonText = useMemo(
		() => (wasRequestSuccess ? 'Go to dashboard' : 'Save & Run'),
		[wasRequestSuccess],
	)
	const handleIsOpen = (id: number) =>
		activeSidebarItem === id
			? setActiveSidebarItem(undefined)
			: setActiveSidebarItem(id)

	useEffect(() => {
		if (activeRegion) {
			setActiveSidebarItem(2)
			void handleGetDates({
				regionId: activeRegion.properties.regionId,
			})
		}
	}, [activeRegion, handleGetDates, setActiveSidebarItem])

	return (
		<Sidebar sidebarWidth={width}>
			<Box height={1} px={2}>
				<Box py={1}>
					<Typography variant="h3">Mapping input</Typography>
				</Box>
				<Box
					height="calc(100% - 60px)"
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
				>
					<Box>
						{sidebarItems.map(({ title, id }: DataProductType, i: number) => {
							const isDisabled = isSidebarItemDisabled(i)

							return (
								<Card
									key={`card-${title}`}
									title={`${i + 1}. ${title}`}
									isOpen={activeSidebarItem === id}
									setIsOpen={() => handleIsOpen(id)}
									disabled={isDisabled}
									customCheckbox={<CustomCheckbox />}
								>
									{i === 0 && (
										<SelectRegion
											regions={regions}
											activeRegion={activeRegion}
											onSetActiveRegion={setActiveRegion}
										/>
									)}

									{i === 1 && (
										<SelectDate
											setDatePreviewImage={setDatePreviewImage}
											datePreviewImage={datePreviewImage}
											isDatePreviewLoading={isDatePreviewLoading}
											handleGetPreview={handleGetPreview}
											activeDate={activeDate}
											activeRegion={activeRegion}
											dates={dates}
											setActiveDate={setActiveDate}
										/>
									)}

									{i === 2 && (
										<PreProcessingSettings
											cloudMask={cloudMask}
											deglint={deglint}
											setCloudMask={setCloudMask}
											setDeglint={setDeglint}
										/>
									)}
								</Card>
							)
						})}
					</Box>
					<Box p={2}>
						<Button
							disabled={!hasActiveDate}
							variant="contained"
							onClick={handleCreateProject}
							fullWidth
						>
							{buttonText}
						</Button>
					</Box>
				</Box>
			</Box>
		</Sidebar>
	)
}

export default SidebarData
