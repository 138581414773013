import { TileLayerProps } from '@deck.gl/geo-layers/tile-layer/tile-layer'
import { TileLayer } from 'deck.gl'
import React, { FC, ReactNode, createContext, useState } from 'react'

interface OrthoContextValues {
	state: {
		showOrtho: boolean
		orthoOpacity: number
	}
	actions: {
		setShowOrtho: (b: boolean) => void
		setOrthoOpacity: (v: number) => void
	}
	layers: {
		orthoLayers: (TileLayer<unknown, TileLayerProps<unknown>> | undefined)[]
		setOrthoLayers: (
			v: (TileLayer<unknown, TileLayerProps<unknown>> | undefined)[],
		) => void
	}
}

interface Props {
	children: ReactNode
}

type Context = OrthoContextValues
export const OrthoContext = createContext<Context>(null as unknown as Context)

const OrthoContextProvider: FC<Props> = ({ children }) => {
	const [orthoOpacity, setOrthoOpacity] = useState(100)
	const [showOrtho, setShowOrtho] = useState(false)
	const [orthoLayers, setOrthoLayers] = useState<
		(TileLayer<unknown, TileLayerProps<unknown>> | undefined)[]
	>([undefined])

	return (
		<OrthoContext.Provider
			value={{
				state: {
					orthoOpacity,
					showOrtho,
				},
				actions: {
					setShowOrtho,
					setOrthoOpacity,
				},
				layers: {
					orthoLayers,
					setOrthoLayers,
				},
			}}
		>
			{children}
		</OrthoContext.Provider>
	)
}
export default OrthoContextProvider
