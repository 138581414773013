import React, { FC } from 'react'
import { Box, Typography, Tooltip, Switch } from '@material-ui/core'
import { useTitleStyles } from './styles'

interface Props {
	setDeglint: (b: boolean) => void
	setCloudMask: (b: boolean) => void
	deglint: boolean
	cloudMask: boolean
}

const PreprocessingSettings: FC<Props> = ({
	deglint,
	cloudMask,
	setDeglint,
	setCloudMask,
}) => {
	const titleClasses = useTitleStyles()

	return (
		<Box>
			<Box width={1} pb={1}>
				<Typography variant="body2">
					Decide whether glint removal and/or cloud-masking should be applied.
				</Typography>
			</Box>
			<Tooltip
				title={
					<>
						<Box mb={0.4}>
							Apply Glint removal to correct the reflection of direct sunlight
							on the air-water interface (sun glint) in the direction of the
							satellite to be able to observe the sea floor. It is recommended
							to use images with no or minimal glint.
						</Box>
						<Box>
							Typically, sun glint forms bands of white along wave edges on the
							windward side of near shore environments. These white bands
							confound the visual identification of bottom features and will
							strongly influence image classification.
						</Box>
					</>
				}
			>
				<Box
					mb={1}
					display="flex"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography className={titleClasses.subtitle} variant="body2">
						Glint removal
					</Typography>
					<Switch
						checked={deglint}
						onChange={(e, val: boolean) => setDeglint(val)}
					/>
				</Box>
			</Tooltip>
			<Tooltip title="Apply Cloud mask to mask out cloudy areas in the classification result. This only applies if input images with clouds have been selected.">
				<Box
					mt={1}
					display="flex"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography className={titleClasses.subtitle} variant="body2">
						Cloud mask
					</Typography>
					<Switch
						checked={cloudMask}
						onChange={(e, val: boolean) => setCloudMask(val)}
					/>
				</Box>
			</Tooltip>
		</Box>
	)
}

export default PreprocessingSettings
