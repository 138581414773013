import React from 'react'
import { hot } from 'react-hot-loader/root' // eslint-disable-line import/no-extraneous-dependencies
import DarkModeProvider from 'sav-features/dark-mode/DarkModeContextProvider'
import { Auth0Provider, AppState } from '@auth0/auth0-react'
import config from 'common/config/auth0.json'
import history from 'sav-utils/history'
import 'typeface-roboto'
import AppThemeWrapper from './AppThemeWrapper'

type OnRedirectCallbackProps = (appState: AppState) => void

const onRedirectCallback: OnRedirectCallbackProps = (appState) => {
	history.push({
		pathname:
			appState && appState.targetUrl
				? appState.targetUrl
				: window.location.pathname,
		search: '',
	})
}

const App = () => (
	<Auth0Provider
		audience={config.audience}
		clientId={config.clientId}
		domain={config.domain}
		onRedirectCallback={onRedirectCallback}
		redirectUri={window.location.href}
	>
		<DarkModeProvider>
			<AppThemeWrapper />
		</DarkModeProvider>
	</Auth0Provider>
)

const isEnvDev = process.env.NODE_ENV === 'development'

export default isEnvDev ? hot(App) : App
