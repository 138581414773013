import React, { FC, useContext } from 'react'
import { Box, Typography } from '@material-ui/core'
import Slider from 'sav-components/Slider'
import { BrightnessContext } from './BrightnessContextProvider'
import { useTitleStyles } from '../create-project/styles'

const BrightnessControl: FC = () => {
	const titleStyles = useTitleStyles()
	const {
		state: { brightness },
		actions: { setBrightness },
	} = useContext(BrightnessContext)
	return (
		<>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Typography variant="body2" className={titleStyles.subtitle}>
					Map controls
				</Typography>
			</Box>
			<Box position="relative" display="flex" height={45} alignItems="center">
				<Slider
					defaultValue={brightness}
					title="Brightness: "
					min={-1}
					max={1}
					step={0.01}
					getValue={(val) => setBrightness(Number(val))}
				/>
			</Box>
		</>
	)
}

export default BrightnessControl
