import React, { FC, ReactNode, createContext, useState } from 'react'

interface SidebarContextValues {
	state: {
		width: number
		activeSidebarItem: number | undefined
	}
	actions: {
		setWidth: (v: number) => void
		setActiveSidebarItem: (n: number | undefined) => void
	}
}

interface Props {
	children: ReactNode
}

type Context = SidebarContextValues
export const SidebarContext = createContext<Context>(null as unknown as Context)

const SidebarContextProvider: FC<Props> = ({ children }) => {
	const [width, setWidth] = useState(400)
	const [activeSidebarItem, setActiveSidebarItem] = useState<
		number | undefined
	>(1)
	return (
		<SidebarContext.Provider
			value={{
				state: { width, activeSidebarItem },
				actions: { setWidth, setActiveSidebarItem },
			}}
		>
			{children}
		</SidebarContext.Provider>
	)
}
export default SidebarContextProvider
