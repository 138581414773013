import React, { useContext, FC } from 'react'
import {
	Box,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
	Switch,
} from '@material-ui/core'
import Slider from 'sav-components/Slider'
import { useTitleStyles } from '../create-project/styles'
import { ProductContext } from './ProductContextProvider'

const PostProcessingSettings: FC = () => {
	const {
		actions: { setSieve, setConnectivity, setMinimumObjectSize },
		state: { sieve, connectivity, minimumObjectSize },
	} = useContext(ProductContext)

	const titleClasses = useTitleStyles()

	return (
		<Box width={1}>
			<Box width={1} mb={2}>
				<Typography variant="body2">
					Choose sieving to remove isolated classified pixels smaller than the
					minimum object size (in pixels) and to replace them with the pixel
					value of the largest neighbour polygon. The sieving method looks at
					the neighbouring 4 or 8 pixels (connectivity) to determine if a pixel
					is grouped with pixels of the same class.
				</Typography>
			</Box>
			<Box
				display="flex"
				alignItems="center"
				mb={1}
				justifyContent="space-between"
			>
				<Typography variant="body2" className={titleClasses.subtitle}>
					Sieve:
				</Typography>
				<Box ml={1}>
					<Switch
						checked={sieve}
						onChange={(e, val: boolean) => setSieve(val)}
					/>
				</Box>
			</Box>
			<Box
				position="relative"
				style={{
					opacity: sieve ? 1 : 0.4,
				}}
			>
				{!sieve && (
					<Box position="absolute" width="100%" height="120%" zIndex={100} />
				)}

				<Box
					mb={1}
					display="flex"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography variant="body2" className={titleClasses.subtitle}>
						Connectivity:{' '}
					</Typography>
					<FormControl
						component="fieldset"
						style={{ marginLeft: 10, width: 150 }}
					>
						<RadioGroup
							value={connectivity}
							defaultValue={4}
							style={{ flexDirection: 'row', justifyContent: 'flex-end' }}
							onChange={(e, val: string) => setConnectivity(Number(val))}
						>
							<FormControlLabel
								value={4}
								style={{ marginLeft: 'unset' }}
								control={<Radio color="primary" />}
								label="4"
							/>
							<FormControlLabel
								value={8}
								style={{ marginLeft: 'unset', marginRight: 'unset' }}
								control={<Radio color="primary" />}
								label="8"
							/>
						</RadioGroup>
					</FormControl>
				</Box>
				<Box>
					<Box display="flex" alignItems="center">
						<Typography variant="body2" className={titleClasses.subtitle}>
							Minimum object size:
						</Typography>
						<Box width={190} ml="10px">
							<Slider
								defaultValue={minimumObjectSize}
								min={4}
								max={500}
								step={4}
								unit="px"
								getValueCommitted={(val: number | number[]) =>
									setMinimumObjectSize(val as number)
								}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default PostProcessingSettings
