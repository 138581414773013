import React, { FC, useContext, ReactNode } from 'react'
import { Box, makeStyles, Grid, Tooltip, IconButton } from '@material-ui/core'
import { LayersOutlined } from '@material-ui/icons'
import LayersPanel from './LayersPanel'
import { LayersPanelContext } from './LayersPanelContextProvider'

const useStyles = makeStyles((theme) => ({
	root: {
		width: 'fit-content',
		height: 'fit-content',
		boxShadow: theme.shadows[4],
		zIndex: 100,
		maxHeight: 'calc(100% - 30px)',
		overflow: 'auto',
	},
	layersIcon: {
		backgroundColor: theme.palette.background.paper,
		height: 36,
		width: 36,
		fill: theme.palette.primary.main,
		padding: 6,
		boxSizing: 'border-box',
	},
	iconButton: {
		padding: 'unset',
	},
	mapLayersWrapper: {
		backgroundColor: theme.palette.background.paper,
		zIndex: 100000,
		width: 300,
	},
	layersIconWrapper: {
		width: 36,
		height: 36,
		zIndex: 100,
	},
}))

interface Props {
	children: ReactNode
}

const LayersPanelControl: FC<Props> = ({ children }) => {
	const classes = useStyles()
	const {
		actions: { setOpenLayersPanel },
		state: { openLayersPanel },
	} = useContext(LayersPanelContext)

	return (
		<Box mb={2} position="absolute" top={15} left={15} className={classes.root}>
			{!openLayersPanel && (
				<Box>
					<Grid container className={classes.layersIconWrapper}>
						<Tooltip placement="right" title="Toggle layers">
							<IconButton className={classes.iconButton}>
								<LayersOutlined
									className={classes.layersIcon}
									onClick={() => setOpenLayersPanel(!openLayersPanel)}
								/>
							</IconButton>
						</Tooltip>
					</Grid>
				</Box>
			)}
			{openLayersPanel && (
				<Box
					className={classes.mapLayersWrapper}
					onClick={(e) => e.stopPropagation()}
				>
					<LayersPanel>{children}</LayersPanel>
				</Box>
			)}
		</Box>
	)
}

export default LayersPanelControl
