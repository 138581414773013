import { useAuth0 } from '@auth0/auth0-react'
import { useSnackbar } from 'notistack'
import React, {
	FC,
	ReactNode,
	createContext,
	useCallback,
	useState,
	useContext,
} from 'react'
import { useHistory } from 'react-router-dom'
import getData from 'sav-utils/getData'
import postData from 'sav-utils/postData'
import { AppContext } from '../app-screen/AppContextProvider'
import { TrainingDataContext } from '../training-data/TrainingDataContextProvider'
import { ViewProjectContext } from '../view-project/ViewProjectContextProvider'
import { RefSystemOptions } from './types'

interface ProductContextValues {
	state: {
		sieve: boolean
		connectivity: number
		minimumObjectSize: number
	}
	actions: {
		setSieve: (b: boolean) => void
		setConnectivity: (c: number) => void
		setMinimumObjectSize: (o: number) => void
		handleAddProduct: () => Promise<void>
		handleDownloadProduct: (refSystem?: RefSystemOptions) => Promise<void>
	}
}

interface Props {
	children: ReactNode
	projectId: string | undefined
}

type Context = ProductContextValues
export const ProductContext = createContext<Context>(null as unknown as Context)

const ProductContextProvider: FC<Props> = ({ children, projectId }) => {
	const {
		actions: { setIsLoading },
	} = useContext(AppContext)
	const {
		state: { trainingFeatureColl, dataCategories },
	} = useContext(TrainingDataContext)
	const {
		state: { currentProject },
	} = useContext(ViewProjectContext)
	const { getAccessTokenSilently } = useAuth0()
	const { enqueueSnackbar } = useSnackbar()
	const history = useHistory()
	const [sieve, setSieve] = useState(true)
	const [connectivity, setConnectivity] = useState(4)
	const [minimumObjectSize, setMinimumObjectSize] = useState(4)
	const handleAddProduct = useCallback(async () => {
		try {
			setIsLoading(true)
			const body = {
				postprocessing_settings: sieve
					? {
							sieve,
							min_object_size: minimumObjectSize,
							connectivity,
					  }
					: { sieve: false },
				project_id: projectId,
			}

			const featureCheck = trainingFeatureColl?.features.reduce(
				(acc, item) =>
					acc.includes(item.properties.className as never)
						? acc
						: [...acc, item.properties.className],
				[],
			)

			if (featureCheck?.length === dataCategories?.length) {
				await postData('/add-product', await getAccessTokenSilently(), body)

				history.push('/')
				enqueueSnackbar(`Product added successfully.`, {
					variant: 'success',
					autoHideDuration: 8000,
				})
			} else {
				enqueueSnackbar(
					`You need to provide at least one polygon for each training data category.`,
					{
						variant: 'error',
						autoHideDuration: 8000,
					},
				)
			}
		} catch (err) {
			enqueueSnackbar(`Product could not be added.`, {
				variant: 'error',
				autoHideDuration: 8000,
			})
		} finally {
			setIsLoading(false)
		}
	}, [
		setIsLoading,
		sieve,
		minimumObjectSize,
		connectivity,
		projectId,
		trainingFeatureColl?.features,
		dataCategories?.length,
		getAccessTokenSilently,
		history,
		enqueueSnackbar,
	])
	const handleDownloadProduct = useCallback(
		async (refSystem?: RefSystemOptions) => {
			try {
				const routePayload = refSystem
					? {
							project_id: currentProject?.id,
							product_id: currentProject?.last_product?.product_id,
							crs: refSystem,
					  }
					: {
							project_id: currentProject?.id,
							product_id: currentProject?.last_product?.product_id,
					  }
				setIsLoading(true)
				const response = await getData(
					'/download-product',
					await getAccessTokenSilently(),
					routePayload,
				)
				window.open(response.download_url)
			} catch (err) {
				enqueueSnackbar(`Data could not be downloaded.`, {
					variant: 'error',
					autoHideDuration: 8000,
				})
			} finally {
				setIsLoading(false)
			}
		},
		[currentProject, enqueueSnackbar, getAccessTokenSilently, setIsLoading],
	)

	return (
		<ProductContext.Provider
			value={{
				state: {
					connectivity,
					minimumObjectSize,
					sieve,
				},
				actions: {
					handleAddProduct,
					handleDownloadProduct,
					setConnectivity,
					setMinimumObjectSize,
					setSieve,
				},
			}}
		>
			{children}
		</ProductContext.Provider>
	)
}
export default ProductContextProvider
