import React, { FC } from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import { RefSystemOptions } from '../product/types'
import { UploadRefOption } from './types'

interface Props {
	onDownloadPolygons: (sys: RefSystemOptions) => void
	options: UploadRefOption[]
}
const CoordSystemPopoverContent: FC<Props> = ({
	onDownloadPolygons,
	options,
}) => (
	<Box p={2} width={1} height={1}>
		<Typography variant="body2" gutterBottom>
			Choose coordinate system
		</Typography>
		<Box mt={1}>
			{options.map((option) => (
				<Box key={`${option.id}-${option.label}`} mb={1}>
					<Button
						variant="contained"
						fullWidth
						color="secondary"
						size="small"
						startIcon={<GetAppRoundedIcon />}
						onClick={() => onDownloadPolygons(option.id)}
					>
						{option.label}
					</Button>
				</Box>
			))}
		</Box>
	</Box>
)

export default CoordSystemPopoverContent
