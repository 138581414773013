import React, {
	FC,
	useContext,
	useRef,
	useMemo,
	useCallback,
	useState,
} from 'react'
import { Box, Button, Grid, Tooltip, Popover } from '@material-ui/core'
import PublishRoundedIcon from '@material-ui/icons/PublishRounded'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import { makeStyles } from '@material-ui/core/styles'
import { RefSystemOptions } from '../product/types'
import {
	DrawFeatureWithProps,
	TrainingDataFeature,
	UploadRefOption,
} from './types'
import { TrainingDataContext } from './TrainingDataContextProvider'
import CoordSystemPopoverContent from './CoordSystemPopoverContent'

const useStyles = makeStyles((theme) => ({
	localDivider: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
}))

interface Props {
	uploadOptions: UploadRefOption[]
}

const TrainingDataUpload: FC<Props> = ({ uploadOptions }) => {
	const classes = useStyles()
	const {
		state: { trainingFeatureColl, dataCategories },
		actions: {
			handleUploadZipFile,
			setTrainingFeatureColl,
			handleDownloadZipFile,
		},
	} = useContext(TrainingDataContext)

	const uploadFile: any = useRef(null)
	const [downloadEl, setDownloadEl] = useState(null)

	const onUploadTrainingData = useCallback(
		async (target: EventTarget & HTMLInputElement) => {
			const { files } = target
			if (files) {
				const localFormattedFeatures = await handleUploadZipFile<
					TrainingDataFeature[]
				>({
					files,
					route: '/upload-training-data',
				})

				if (trainingFeatureColl && localFormattedFeatures)
					setTrainingFeatureColl({
						...trainingFeatureColl,
						features: [
							...localFormattedFeatures,
							...trainingFeatureColl.features,
						] as DrawFeatureWithProps[],
					})
			}
		},
		[trainingFeatureColl, setTrainingFeatureColl, handleUploadZipFile],
	)
	const dataCategoriesString = useMemo(() => {
		const classesArray = dataCategories?.map(
			(classObj) => `"${String(classObj.className)}"`,
		)
		if (classesArray) {
			const lastItem = classesArray[classesArray.length - 1]
			const withoutLastItem = classesArray.slice(0, classesArray.length - 1)
			return `${String(withoutLastItem.join(', '))} and/or ${String(lastItem)}`
		}
		return ''
	}, [dataCategories])

	const handleDownloadPolygons = (refSystem: RefSystemOptions) => {
		void handleDownloadZipFile({ refSystem })
	}

	const handlePopoverOpen = (event: any) => {
		if (event.currentTarget) {
			setDownloadEl(event.currentTarget)
		}
	}

	const handlePopoverClose = () => {
		setDownloadEl(null)
	}

	const isPopoverOpen = useMemo(() => Boolean(downloadEl), [downloadEl])

	return (
		<>
			<Box width={1} my={0.5} className={classes.localDivider} />
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<Box display="flex" justifyContent="flex-start">
						<Popover
							id="download-popover"
							open={isPopoverOpen}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							anchorEl={downloadEl}
							onClose={handlePopoverClose}
						>
							<CoordSystemPopoverContent
								onDownloadPolygons={handleDownloadPolygons}
								options={uploadOptions}
							/>
						</Popover>
						<Tooltip title="in zipped .geojson format">
							<Button
								aria-owns={isPopoverOpen ? 'download-popover' : undefined}
								aria-haspopup="true"
								variant="outlined"
								fullWidth
								color="secondary"
								onClick={(e) => handlePopoverOpen(e)}
								disabled={trainingFeatureColl?.features.length === 0}
								disableElevation
								size="small"
								startIcon={<GetAppRoundedIcon />}
							>
								Download polygons
							</Button>
						</Tooltip>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box display="flex" justifyContent="flex-end">
						<input
							accept=".zip"
							type="file"
							id="file-upload-json"
							style={{ display: 'none' }}
							ref={uploadFile}
							onChange={({ target }) => {
								void onUploadTrainingData(target)
								// eslint-disable-next-line no-param-reassign
								target.value = ''
							}}
						/>
						<Tooltip
							title={`Zipped shapefiles or a zipped GeoJSON file containing a column named “class_name” with classes ${dataCategoriesString}.`}
						>
							<Button
								variant="outlined"
								fullWidth
								size="small"
								color="secondary"
								disableElevation
								startIcon={<PublishRoundedIcon />}
								onClick={() => uploadFile.current.click()}
							>
								Upload polygons
							</Button>
						</Tooltip>
					</Box>
				</Grid>
			</Grid>
		</>
	)
}

export default TrainingDataUpload
