import React, { FC } from 'react'
import { Avatar, Box, Typography, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import {
	HomeOutlined as HomeOutlinedIcon,
	ExitToApp as ExitToAppIcon,
} from '@material-ui/icons'
import { IMikeTheme } from '@dhi/react-components-lab'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	wrapper: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderLeft: `1px solid ${String(theme.palette.grey[100])}`,
		paddingLeft: '16px',
	},
	avatar: {
		width: 40,
		height: 40,
		backgroundColor: theme.palette.primary?.main,
	},
	icon: {
		width: 26,
		height: 26,
		cursor: 'pointer',
	},
	exitToApp: {
		width: 22,
		height: 22,
		cursor: 'pointer',
	},
	avatarText: {
		color: theme.palette.grey[100],
	},
}))

interface Props {
	username: string
	onLogout: () => void
}

const Profile: FC<Props> = ({ username, onLogout }) => {
	const history = useHistory()
	const classes = useStyles()

	return (
		<Box className={classes.wrapper} style={{ width: username ? 140 : 120 }}>
			<Tooltip title="Go to dashboard">
				<HomeOutlinedIcon
					color="primary"
					className={classes.icon}
					onClick={() => history.push('/')}
				/>
			</Tooltip>
			{username && (
				<>
					<Tooltip title="Logout">
						<ExitToAppIcon
							color="primary"
							className={classes.exitToApp}
							onClick={onLogout}
						/>
					</Tooltip>
					<Avatar className={classes.avatar} color="primary">
						<Typography variant="body1" className={classes.avatarText}>
							{username ? username.substring(0, 2).toUpperCase() : ''}
						</Typography>
					</Avatar>
				</>
			)}
		</Box>
	)
}

export default Profile
