import React, { FC } from 'react'
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Checkbox,
	IconButton,
} from '@material-ui/core'
import {
	RadioButtonUnchecked,
	CheckCircle,
	FiberManualRecordRounded,
	ControlPoint,
	VisibilityOffOutlined,
	VisibilityOutlined,
	Edit,
} from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'
import { IMikeTheme } from '@dhi/react-components-lab'
import clsx from 'clsx'
import { DataClass, Draw, PixelData } from './types'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	checkIcon: {
		width: 24,
		height: 24,
		color: theme.palette.primary.dark,
	},
	checkIconActive: {
		color: theme.palette.success.main,
	},
	dataRow: {
		cursor: 'pointer',
	},
	dataRowSelected: {
		backgroundColor: theme.palette.grey[50],
	},
	toggleIcon: {
		width: 22,
		height: 22,
	},
	tableCell: {
		'&.MuiTableCell-sizeSmall': {
			padding: 0,
		},
	},
}))

interface Props {
	dataCategories: DataClass[]
	pixelCount: PixelData | undefined
	draw: Draw | undefined
	handleDraw: (classObj: DataClass) => void
	handleDeletePolygons: () => void
	showTrainingData: boolean
	setShowTrainingData: (v: boolean) => void
	maxClasses: number
	hasEdit?: boolean
	setActiveEditDataCategory?: (v: DataClass) => void
}

const TrainingDataTable: FC<Props> = ({
	dataCategories,
	pixelCount,
	draw,
	hasEdit,
	handleDraw,
	handleDeletePolygons,
	showTrainingData,
	setShowTrainingData,
	maxClasses,
	setActiveEditDataCategory,
}) => {
	const classes = useStyles()

	return (
		<Box mt={1}>
			<TableContainer>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell
								className={classes.tableCell}
								align="center"
								color="primary"
								size="medium"
							>
								<Box display="flex" alignItems="center" justifyContent="center">
									<IconButton
										size="small"
										onClick={() => setShowTrainingData(!showTrainingData)}
									>
										{showTrainingData ? (
											<VisibilityOutlined className={classes.toggleIcon} />
										) : (
											<VisibilityOffOutlined className={classes.toggleIcon} />
										)}
									</IconButton>
								</Box>
							</TableCell>
							<TableCell
								className={classes.tableCell}
								align="left"
								color="primary"
							>
								Name
							</TableCell>
							<TableCell
								className={classes.tableCell}
								align="center"
								color="primary"
							>
								Color
							</TableCell>
							<TableCell
								className={classes.tableCell}
								align="center"
								color="primary"
							>
								<Box>Area</Box>
							</TableCell>
							{hasEdit && (
								<TableCell
									className={classes.tableCell}
									color="primary"
									align="right"
								>
									<Box>Actions</Box>
								</TableCell>
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{dataCategories.map((classObj) => {
							const isSelected =
								draw?.title.toLowerCase() === classObj.className.toLowerCase()
							return (
								<TableRow
									className={clsx(
										classes.dataRow,
										isSelected && classes.dataRowSelected,
									)}
									onClick={() => handleDraw(classObj)}
									key={`table-row-${String(classObj.className)}`}
								>
									<TableCell
										className={classes.tableCell}
										align="center"
										color="primary"
										size="medium"
									>
										<Box
											display="flex"
											alignItems="center"
											justifyContent="center"
										>
											<Checkbox
												checked={isSelected}
												icon={
													<RadioButtonUnchecked
														color="primary"
														className={classes.checkIcon}
													/>
												}
												checkedIcon={
													<CheckCircle
														className={clsx(
															classes.checkIcon,
															isSelected && classes.checkIconActive,
														)}
													/>
												}
											/>
										</Box>
									</TableCell>
									<TableCell className={classes.tableCell}>
										<Box
											display="flex"
											alignItems="center"
											justifyContent="flex-start"
										>
											<Typography variant="body2">
												{classObj.className}
											</Typography>
										</Box>
									</TableCell>
									<TableCell className={classes.tableCell}>
										<Box
											display="flex"
											alignItems="center"
											justifyContent="center"
										>
											<FiberManualRecordRounded
												htmlColor={classObj.classColor}
												className={classes.icon}
											/>
										</Box>
									</TableCell>
									<TableCell className={classes.tableCell}>
										<Box
											display="flex"
											alignItems="center"
											justifyContent="center"
										>
											<Typography variant="body2">
												{pixelCount && pixelCount?.[classObj.className]
													? `${Number(
															pixelCount?.[classObj.className]?.toFixed(2),
													  )}`
													: '-'}
											</Typography>
											{pixelCount && pixelCount[classObj.className] && (
												<Box ml={0.5} display="flex">
													km
													<Box display="flex" alignItems="flex-start">
														<span style={{ fontSize: 8 }}>{2}</span>
													</Box>
												</Box>
											)}
										</Box>
									</TableCell>
									{hasEdit && (
										<TableCell align="right" className={classes.tableCell}>
											<IconButton
												size="medium"
												onClick={() => setActiveEditDataCategory?.(classObj)}
											>
												<Edit fontSize="small" />
											</IconButton>
										</TableCell>
									)}
								</TableRow>
							)
						})}
						{Number(dataCategories?.length) < maxClasses && (
							<TableRow
								className={classes.dataRow}
								onClick={handleDeletePolygons}
							>
								<TableCell
									className={classes.tableCell}
									align="center"
									size="medium"
								>
									<Box
										display="flex"
										alignItems="center"
										justifyContent="center"
									>
										<IconButton size="medium">
											<ControlPoint color="primary" />
										</IconButton>
									</Box>
								</TableCell>
								<TableCell className={classes.tableCell}>
									<Box
										width={65}
										display="flex"
										alignItems="center"
										justifyContent="center"
									>
										New class
									</Box>
								</TableCell>
								<TableCell className={classes.tableCell} />
								<TableCell className={classes.tableCell} />
								<TableCell className={classes.tableCell} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}

export default TrainingDataTable
