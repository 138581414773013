import React, { FC } from 'react'
import { Box, Grid, Typography, makeStyles, GridSize } from '@material-ui/core'
import { IMikeTheme } from '@dhi/react-components-lab'
import clsx from 'clsx'
import { HeaderItems } from './types'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	headerBox: {
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.palette.grey[100],
		},
	},
	root: {
		backgroundColor: theme.palette.grey[50],
		border: `1px solid ${String(theme.palette.grey[200])}`,
		borderTop: 'none',
	},
	wrapper: {
		'&:first-child': {
			paddingLeft: 16,
		},
		'&:last-child': {
			paddingRight: 16,
		},
	},
	headerItem: {
		paddingTop: 8,
	},
	itemText: {
		padding: 0,
		paddingLeft: 1,
		fontWeight: 500,
	},
}))

interface Props {
	onColumnSort: (s: string) => void
	headerItems: HeaderItems
}

const TableHeader: FC<Props> = ({ onColumnSort, headerItems }) => {
	const classes = useStyles()
	const headerItemsArray = Object.keys(headerItems)
	return (
		<Box width={1} className={classes.root}>
			<Grid container className={classes.wrapper}>
				{headerItemsArray.map((item: string, i) => {
					const isLastItem = i === headerItemsArray.length - 1

					return (
						<Grid
							item
							key={item}
							xs={headerItems[item].gridSize as GridSize}
							className={clsx(
								!isLastItem && classes.headerBox,
								classes.headerItem,
							)}
							onClick={() => onColumnSort(item)}
						>
							<Typography
								variant="body2"
								key={item}
								className={classes.itemText}
								style={{
									textAlign: isLastItem ? 'right' : 'left',
								}}
								gutterBottom
							>
								{headerItems[item].label}
							</Typography>
						</Grid>
					)
				})}
			</Grid>
		</Box>
	)
}

export default TableHeader
