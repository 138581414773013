import React, { FC, useMemo } from 'react'
import {
	AppBar,
	Box,
	Grid,
	Toolbar,
	Typography,
	makeStyles,
	useTheme,
	PaletteType,
} from '@material-ui/core'
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent'
import { User } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'
import Profile from './Profile'

const useStyles = makeStyles((theme) => ({
	link: {
		display: 'block',
		textDecoration: 'none',
		color: 'inherit',
		height: '100%',
	},
	appbar: {
		backgroundColor: theme.palette.grey[50],
		borderBottom: `2px solid ${String(theme.palette.grey[100])}`,
		padding: '0px 16px',
	},
	logo: {
		height: '50%',
		width: 'auto',
	},
	lightIcon: {
		cursor: 'pointer',
	},
}))

interface Props {
	position: 'relative' | 'fixed'
	user: User
	logout: () => void
	description: string | undefined
	hasDarkMode?: boolean | undefined
	setDarkMode?: (themeType: PaletteType) => void
	title?: string | undefined
}

const Navigation: FC<Props> = ({
	position,
	user,
	logout,
	description,
	hasDarkMode = false,
	setDarkMode,
	title,
}) => {
	const classes = useStyles()
	const theme = useTheme()

	const isDarkTheme = useMemo(
		() => theme.palette.type === 'dark',
		[theme.palette.type],
	)

	return (
		<AppBar position={position} elevation={0} className={classes.appbar}>
			<Grid container alignItems="center" justify="space-between">
				<Grid item xs={8}>
					<Toolbar disableGutters>
						<Link to="/" className={classes.link}>
							<Box height={1} pl={1} display="flex" alignItems="center">
								<img
									src={
										isDarkTheme
											? 'https://grasdatastorage.blob.core.windows.net/images/DHI_Logo_Light.svg'
											: 'https://grasdatastorage.blob.core.windows.net/images/DHI_Logo_Blue.svg'
									}
									className={classes.logo}
									alt="DHI logo"
								/>
							</Box>
						</Link>
						<Box ml={2}>
							<Typography variant="h5" color="primary" noWrap gutterBottom>
								{title || 'Submerged Aquatic Vegetation'}
							</Typography>
							<Typography variant="body2">{description}</Typography>
						</Box>
					</Toolbar>
				</Grid>
				<Grid container item xs={4} justify="flex-end">
					{hasDarkMode && (
						<Box height={1} m={1} mx={2} display="flex" alignItems="center">
							<WbIncandescentIcon
								className={classes.lightIcon}
								color="primary"
								onClick={() =>
									setDarkMode !== undefined &&
									setDarkMode(isDarkTheme ? 'light' : 'dark')
								}
							/>
						</Box>
					)}
					{user && (
						<Profile username={String(user?.nickname)} onLogout={logout} />
					)}
				</Grid>
			</Grid>
		</AppBar>
	)
}

Navigation.defaultProps = {
	hasDarkMode: false,
	setDarkMode: undefined,
}

export default Navigation
