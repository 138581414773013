import React, {
	FC,
	ReactNode,
	createContext,
	useState,
	useCallback,
} from 'react'
import { TileLayer } from '@deck.gl/geo-layers'
import { TileLayerProps } from '@deck.gl/geo-layers/tile-layer/tile-layer'
import { brightnessContrast } from '@luma.gl/shadertools/src/modules/image-adjust-filters/brightnesscontrast'
import { PostProcessEffect } from '@deck.gl/core'
import { ProjectType } from '../app-screen/types'

interface BrightnessContextValues {
	state: {
		brightness: number
	}
	actions: {
		setBrightness: (o: number) => void
	}
	effects: {
		brightnessEffect: PostProcessEffect
	}
}

const TERRACOTTA_URL = process.env.REACT_APP_TERACCOTTA_URL

interface Props {
	children: ReactNode
}

type Context = BrightnessContextValues
export const BrightnessContext = createContext<Context>(
	null as unknown as Context,
)
// Brightness stands for Feasibility
const BrightnessContextProvider: FC<Props> = ({ children }) => {
	const [brightness, setBrightness] = useState(0)

	const brightnessEffect = new PostProcessEffect(brightnessContrast, {
		brightness,
		contrast: 0,
	})

	return (
		<BrightnessContext.Provider
			value={{
				state: {
					brightness,
				},
				actions: {
					setBrightness,
				},
				effects: {
					brightnessEffect,
				},
			}}
		>
			{children}
		</BrightnessContext.Provider>
	)
}
export default BrightnessContextProvider
