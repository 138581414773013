import { WebMercatorViewport } from 'react-map-gl'
import bbox from '@turf/bbox'
import { GeoJSON } from 'geojson'

type ReturnType = {
	zoom: number
	latitude: number
	longitude: number
}

export default (
	activeGeometry: GeoJSON,
	scrollHeight: number,
	scrollWidth: number,
): ReturnType => {
	const givenBbox = bbox(activeGeometry)
	const formattedBounds: [[number, number], [number, number]] = [
		[givenBbox[0], givenBbox[1]],
		[givenBbox[2], givenBbox[3]],
	]

	if (formattedBounds[0][0] >= 89) formattedBounds[0][0] = 89
	if (formattedBounds[0][1] <= -89) formattedBounds[0][1] = -89
	if (formattedBounds[1][0] >= 179) formattedBounds[1][0] = 179
	if (formattedBounds[1][1] >= 89) formattedBounds[0][0] = 89

	const viewportBounds = new WebMercatorViewport({
		width: scrollWidth,
		height: scrollHeight,
	}).fitBounds(formattedBounds, { padding: 40 })

	return {
		zoom: viewportBounds.zoom,
		latitude: viewportBounds.latitude,
		longitude: viewportBounds.longitude,
	}
}
