import React, { ReactNode, FC } from 'react'
import { SnackbarProvider } from 'notistack'
import { useTheme, withStyles } from '@material-ui/core'
import 'typeface-roboto'
import AppContextProvider, { PortalName } from './AppContextProvider'
import SidebarContextProvider from '../sidebar/SidebarContextProvider'

interface Props {
	children: ReactNode
	portalName: PortalName
}

const AppScreenWrapper: FC<Props> = ({ children, portalName }) => {
	const theme = useTheme()
	const CustomSnackbarProvider = withStyles({
		contentRoot: {
			'& #notistack-snackbar': {
				color: `${theme.palette.primary.contrastText}`,
				fontWeight: 500,
			},
		},
		variantSuccess: {
			backgroundColor: theme.palette.success.main,
		},
		variantError: {
			backgroundColor: theme.palette.error.main,
			color: theme.palette.text.primary,
		},
		variantWarning: {
			backgroundColor: theme.palette.warning.main,
			color: theme.palette.text.primary,
		},
		variantInfo: {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.text.primary,
		},
	})(SnackbarProvider)

	return (
		<CustomSnackbarProvider>
			<AppContextProvider portalName={portalName}>
				<SidebarContextProvider>{children}</SidebarContextProvider>
			</AppContextProvider>
		</CustomSnackbarProvider>
	)
}

export default AppScreenWrapper
