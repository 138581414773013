// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/require-default-props */
import React, {
	ReactNode,
	FC,
	useState,
	useEffect,
	ChangeEvent,
	useRef,
	useCallback,
} from 'react'
import {
	Slider as MSlider,
	Box,
	Typography,
	InputBase,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
	inputBaseValue: {
		fontSize: 'unset',
		'& input': {
			padding: 'unset',
		},
	},
	valueBox: {
		height: '100%',
		width: '2rem',
	},
	valueText: {
		cursor: 'pointer',
	},
	slider: {
		margin: '0rem .6rem',
	},
}))

type SliderProps = {
	/**
	 * Default values of the slider in format `[min, max]` or `number`.
	 */
	defaultValue: number[] | number
	title?: string | ReactNode | undefined
	min?: number | undefined
	max?: number | undefined
	step?: number | undefined
	/**
	 * Get the value while using the slider thumb.
	 */
	getValue?: (val: number | number[]) => void
	/**
	 * Get the value once you release the slider thumb.
	 */
	getValueCommitted?: (val: number | number[]) => void
	noNumbers?: boolean | undefined
	/**
	 * Append a unit at the end of the values. (%, /10, £, $)
	 */
	unit?: string | undefined
}

const Slider: FC<SliderProps> = ({
	defaultValue = [0, 1],
	title,
	min = 0,
	max = 1,
	step = 0.01,
	getValue,
	getValueCommitted,
	noNumbers = false,
	unit = '',
	// eslint-disable-next-line radar/cognitive-complexity
}) => {
	const refTextSliderValue0 = useRef(null)
	const refTextSliderValue1 = useRef(null)
	const classes = useStyles()
	const [value, setValue] = useState<number | number[] | undefined>(undefined)
	const [tempValue, setTempValue] = useState<
		string | number | (string | number)[] | undefined
	>(undefined)
	const [activeInput, setActiveInput] = useState<string | undefined>(undefined)

	const handleChange = useCallback(
		(newValue: number | number[]) => {
			setValue(newValue)
			getValue?.(newValue)
		},
		[getValue],
	)

	const handleChangeTemp = useCallback((val: string | (string | number)[]) => {
		setTempValue(val)
	}, [])

	useEffect(() => {
		setValue(defaultValue)
	}, [defaultValue])

	const handleShowInput = useCallback(
		(input: 'value0' | 'value1') => {
			setActiveInput(input)
			setTempValue(value)
		},
		[value],
	)

	const handleChangeInputBase = useCallback(
		(
			e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
			input: 'value0' | 'value1',
		) => {
			if (input === 'value0' && value !== undefined) {
				const inputValue = e.target.value
				const localValue = [inputValue, value[1]]
				handleChangeTemp(localValue)
			}

			if (input === 'value1' && value !== undefined) {
				const inputValue = e.target.value
				const localValue = Array.isArray(value)
					? [value[0], inputValue]
					: inputValue
				handleChangeTemp(localValue)
			}
		},
		[handleChangeTemp, value],
	)

	const handleValueCommit = useCallback(() => {
		if (tempValue) {
			let finalValue: any = tempValue
			if (Array.isArray(tempValue)) {
				const finalValue0 = parseInt(String(finalValue[0]), 10)
				const finalValue1 = parseInt(String(finalValue[1]), 10)

				finalValue = [finalValue0, finalValue1]

				if (finalValue[0] < min || finalValue[0] > max) {
					finalValue[0] = min
				}

				if (finalValue[1] < min || finalValue[1] > max) {
					finalValue[1] = max
				}

				if (finalValue[0] >= finalValue[1]) finalValue[0] = finalValue[1] - 1
				if (finalValue[1] <= finalValue[0])
					finalValue[1] = parseInt(String(finalValue[1]), 10) + 1
			} else {
				finalValue = parseInt(String(finalValue), 10)
				if (finalValue < min) finalValue = min
				if (finalValue > max) finalValue = max
			}
			handleChange(finalValue)
			getValueCommitted?.(finalValue)
			setTempValue(undefined)
		}
		setActiveInput(undefined)
	}, [getValueCommitted, handleChange, max, min, tempValue])

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			flexWrap="nowrap"
			width={1}
		>
			{title && (
				<Box height={1} marginRight={noNumbers ? '1rem' : '0rem'}>
					<Typography variant="body2">{title}</Typography>
				</Box>
			)}

			{!noNumbers && typeof value?.[0] === 'number' && (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					className={classes.valueBox}
					ml={1}
					minWidth={32}
				>
					{activeInput === 'value0' ? (
						<InputBase
							className={classes.inputBaseValue}
							id="inputValue0"
							value={tempValue?.[0] ?? value?.[0]}
							onChange={(e) => handleChangeInputBase(e, 'value0')}
							onBlur={handleValueCommit}
							onKeyDown={(e) => e.key === 'Enter' && handleValueCommit()}
							autoFocus
							ref={refTextSliderValue0}
						/>
					) : (
						<Typography
							variant="body2"
							onClick={() => handleShowInput('value0')}
							className={classes.valueText}
						>
							{String(tempValue?.[0] ?? value?.[0]) + unit}
						</Typography>
					)}
				</Box>
			)}

			{value !== undefined && (
				<MSlider
					className={classes.slider}
					value={value}
					min={min}
					step={step}
					max={max}
					scale={(x) => x / 10}
					onChange={(e, val) => handleChange(val)}
					onChangeCommitted={(e, val) => getValueCommitted?.(val)}
					valueLabelDisplay="off"
				/>
			)}
			{!noNumbers && (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					className={classes.valueBox}
					ml={1}
					minWidth={32}
				>
					{activeInput === 'value1' ? (
						<InputBase
							className={classes.inputBaseValue}
							id="inputValue1"
							value={
								Array.isArray(tempValue)
									? tempValue?.[1] ?? value?.[1]
									: tempValue ?? value
							}
							onChange={(e) => handleChangeInputBase(e, 'value1')}
							onBlur={handleValueCommit}
							onKeyDown={(e) => e.key === 'Enter' && handleValueCommit()}
							autoFocus
							ref={refTextSliderValue1}
						/>
					) : (
						<Typography
							variant="body2"
							onClick={() => handleShowInput('value1')}
							className={classes.valueText}
						>
							{`${String(
								(tempValue?.[1] ?? value?.[1]) || (tempValue ?? value),
							)}${unit}`}
						</Typography>
					)}
				</Box>
			)}
		</Box>
	)
}

export default Slider
