import React, { FC } from 'react'
import { Box } from '@material-ui/core'

interface Props {
	trainingColor: string | undefined
	classColor: string
}

const TrainingDataClassPreviewSvg: FC<Props> = ({
	trainingColor,
	classColor,
}) => (
	<Box>
		<svg
			version="1.2"
			baseProfile="tiny-ps"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 412 101"
			width="412"
			height="101"
		>
			<title>trianing_preview</title>
			<defs>
				<image
					width="412"
					height="101"
					id="img1"
					href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZwAAABlAQMAAABDZccfAAAAAXNSR0IB2cksfwAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAAcSURBVHic7cEBDQAAAMKg909tDjegAAAAAADODRTpAAFroFdUAAAAAElFTkSuQmCC"
				/>
			</defs>
			<use id="Background" href="#img1" x="0" y="0" />
			<path
				style={{
					fill: classColor,
				}}
				id="Shape 1"
				d="M206 -27L447 -27L447 50L206 50L206 -27Z"
			/>
			<path
				id="Shape 2"
				style={{
					fill: classColor,
				}}
				d="M161 37L466 37L466 141L161 141L161 37Z"
			/>
			<path
				id="Shape 3"
				style={{
					fill: 'none',
					stroke: trainingColor,
					strokeWidth: 3,
				}}
				d="M57 19L105 19L105 69L57 69L57 19Z"
			/>
			<path
				id="Shape 4"
				style={{
					fill: 'none',
					stroke: trainingColor,
					strokeWidth: 3,
				}}
				d="M181 12L230 12L230 50L181 50L181 12Z"
			/>
		</svg>
	</Box>
)

export default TrainingDataClassPreviewSvg
