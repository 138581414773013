import React, { FC } from 'react'
import {
	Grid,
	Typography,
	makeStyles,
	Box,
	Tooltip,
	useTheme,
} from '@material-ui/core'
import moment from 'moment'
import ClearIcon from '@material-ui/icons/Clear'
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import InfoIcon from '@material-ui/icons/Info'
import { ReplayOutlined as ReloadIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { PCProjectType, ProjectType } from '../app-screen/types'

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 4,
		backgroundColor: theme.palette.background.paper,
		maxHeight: '42vh',
		overflowY: 'auto',
	},
	icon: {
		width: 20,
		height: 20,
		marginLeft: 25,
	},
	errorIcon: {
		cursor: 'pointer',
	},
	tableRow: {
		border: '1px solid',
		borderColor: theme.palette.grey[200],
		borderTop: 'none',
		padding: '4px 16px',
		backgroundColor: theme.palette.background.paper,
		'&:last-child': {
			borderRadius: '0px 0px 6px 6px',
		},
		'&:nth-child(2n)': {
			backgroundColor: theme.palette.grey[50],
		},
	},
}))

interface Props {
	deleteProject: (n: number) => void
	onViewProject: (n: number) => void
	projects: any
	isAppLoading: boolean
	reloadProject?: (n: number) => void
	hasPreProcessing?: boolean
	hasProjectDetails?: boolean
}

const TableContent: FC<Props> = ({
	deleteProject,
	projects,
	onViewProject,
	reloadProject,
	isAppLoading,
	hasPreProcessing,
	hasProjectDetails,
}) => {
	const classes = useStyles()
	const formatStatusText = (status: string) => {
		const localStatus = status.toLowerCase()
		return localStatus.charAt(0).toUpperCase() + localStatus.slice(1)
	}
	const theme = useTheme()
	const successColor = theme.palette.success.main
	const runningColor = theme.palette.warning.main
	const failedColor = theme.palette.error.main

	const inactiveColor = theme.palette.grey[500]
	const activeColor = theme.palette.text.primary

	if (projects.length === 0) {
		return (
			<Box p={1} className={classes.root}>
				<Typography variant="body2" align="center">
					No projects available
				</Typography>
			</Box>
		)
	}

	return (
		<Box className={classes.root}>
			{projects &&
				// eslint-disable-next-line radar/cognitive-complexity
				projects?.map((project: ProjectType | PCProjectType) => {
					const isPreCompleted =
						!hasPreProcessing || project['preprocessing_status'] === 'COMPLETED'
					const isPreRunning = project['preprocessing_status'] === 'RUNNING'
					const isPreFailed = project['preprocessing_status'] === 'FAILED'
					const hasPostStatus = Boolean(project.last_product)

					const isPostCompleted = project.last_product?.status === 'COMPLETED'
					const isPostRunning = project.last_product?.status === 'RUNNING'
					const isPostFailed = project.last_product?.status === 'FAILED'

					const hasErrorFailed = project.last_product?.error_message
					// handles the logic for the globe icon, based on the pre/post processing statuses.
					const handleGlobe = <T, M>(truthyValue: T, falsyValue: M): T | M => {
						if (hasPostStatus) {
							if ((isPostCompleted || isPostFailed) && isPreCompleted)
								return truthyValue
							return falsyValue
						}
						if (isPreCompleted) return truthyValue
						return falsyValue
					}

					return (
						<Grid container key={project.id} className={classes.tableRow}>
							{hasProjectDetails ? (
								<>
									<Grid container item xs={1} alignItems="center">
										<Typography variant="body2" color="primary">
											{`${project.id}`}
										</Typography>
									</Grid>
									<Grid container item xs={3} alignItems="center">
										<Typography variant="body2" color="primary">
											{`${String((project as ProjectType).region_name)}`}
										</Typography>
									</Grid>
								</>
							) : (
								<>
									{/* Region */}
									<Grid container item xs={2} alignItems="center">
										<Typography variant="body2" color="primary">
											{project.region_geometry.properties.id}
										</Typography>
									</Grid>
									{/* Project */}

									<Grid container item xs={2} alignItems="center">
										<Typography variant="body2" color="primary">
											{project.id}
										</Typography>
									</Grid>
								</>
							)}

							{/* Satellite image date */}
							<Grid container item xs={2} alignItems="center">
								<Typography variant="body2" color="primary">
									{moment(project.acquisition_date).format('DD-MM-YYYY')}
								</Typography>
							</Grid>
							{/* Pre-processing state */}
							{hasPreProcessing && (
								<Grid container item xs={2} alignItems="center">
									{isPreCompleted && (
										<CheckCircleRoundedIcon
											htmlColor={successColor}
											className={classes.icon}
										/>
									)}
									{isPreRunning && (
										<InfoIcon
											htmlColor={runningColor}
											className={classes.icon}
										/>
									)}
									{isPreFailed && (
										<InfoIcon
											htmlColor={failedColor}
											className={classes.icon}
										/>
									)}
									<Typography variant="body2" color="primary">
										{formatStatusText(project['preprocessing_status'])}
									</Typography>
								</Grid>
							)}

							{/* processing_state */}
							<Grid container item xs={2} alignItems="center">
								{isPostCompleted && (
									<CheckCircleRoundedIcon
										htmlColor={successColor}
										className={classes.icon}
									/>
								)}
								{isPostRunning && (
									<InfoIcon htmlColor={runningColor} className={classes.icon} />
								)}
								{isPostFailed && (
									<Tooltip
										title={
											hasErrorFailed ||
											'Reason of failure unclear. Try to rerun with additional training polygons.'
										}
									>
										<InfoIcon
											htmlColor={failedColor}
											className={clsx(classes.icon, classes.errorIcon)}
										/>
									</Tooltip>
								)}
								<Typography variant="body2" color="primary">
									{project.last_product
										? `${formatStatusText(project.last_product.status)}`
										: '-'}
								</Typography>
							</Grid>
							{/* actions */}
							<Grid
								container
								item
								xs={hasPreProcessing ? 2 : 4}
								justify="flex-end"
								alignItems="center"
							>
								{/* re-run project */}
								{isPreFailed && (
									<Tooltip title="Re-run project">
										<ReloadIcon
											style={{
												cursor: 'pointer',
												color: isAppLoading ? inactiveColor : activeColor,
											}}
											className={classes.icon}
											onClick={() => reloadProject?.(Number(project.id))}
										/>
									</Tooltip>
								)}
								{/* globe - go to project */}
								<Tooltip
									title="Go to project"
									open={handleGlobe(undefined, false)}
								>
									<PublicOutlinedIcon
										onClick={() =>
											!(isPostRunning || isPreRunning) &&
											handleGlobe(
												onViewProject(Number(project.id)),
												onViewProject(Number(project.id)),
											)
										}
										className={classes.icon}
										style={{
											cursor: handleGlobe('pointer', 'cursor'),
										}}
										htmlColor={handleGlobe(activeColor, inactiveColor)}
									/>
								</Tooltip>
								{/* delete project */}
								<Tooltip title="Delete project">
									<ClearIcon
										style={{
											cursor: 'pointer',
											color: isAppLoading ? inactiveColor : failedColor,
										}}
										onClick={() => deleteProject(Number(project.id))}
										className={clsx(classes.icon)}
									/>
								</Tooltip>
							</Grid>
						</Grid>
					)
				})}
		</Box>
	)
}

export default TableContent
