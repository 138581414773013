import React, { FC, useContext, useMemo, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { DataClass } from './types'
import TrainingDataTable from './TrainingDataTable'
import AddTrainingClassesDialog from './AddTrainingClassesDialog'
import { TrainingDataContext } from './TrainingDataContextProvider'
import { AppContext, PortalName } from '../app-screen/AppContextProvider'

interface Props {
	startDrawing: (title: string, color: string) => void
	maxClasses?: number | undefined
}

const TrainingDataHeader: FC<Props> = ({ startDrawing, maxClasses = 5 }) => {
	const {
		state: { dataCategories, draw, pixelCount, showTrainingData },
		actions: { setShowTrainingData },
	} = useContext(TrainingDataContext)
	const {
		state: { portalName },
	} = useContext(AppContext)

	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [activeEditDataCategory, setActiveEditDataCategory] = useState<
		DataClass | undefined
	>(undefined)

	const handleDraw = (classObj: DataClass) =>
		startDrawing(classObj.className, classObj.classColor)
	const handleClassesDialog = () => setIsDialogOpen(true)
	const dataCategoriesString = useMemo(() => {
		const classesArray = dataCategories?.map(
			(classObj) => `"${String(classObj.className)}"`,
		)
		if (classesArray) {
			const lastItem = classesArray[classesArray.length - 1]
			const withoutLastItem = classesArray.slice(0, classesArray.length - 1)
			return `${String(withoutLastItem.join(', '))} and ${String(lastItem)}`
		}
		return ''
	}, [dataCategories])

	const isOnSwedishApp = portalName === PortalName.Sweden
	const handleEditDataCategory = (dataCategory: DataClass) => {
		setActiveEditDataCategory(dataCategory)
		setIsDialogOpen(true)
	}
	const handleOnCloseDialog = () => {
		setIsDialogOpen(false)
		setActiveEditDataCategory(undefined)
	}
	return (
		<>
			<Box width={1} pb={1}>
				<Typography variant="body2">
					Click on a habitat type and draw polygons on the satellite image to
					collect training data or upload training polygons. In total five
					classes are available. {dataCategoriesString} are required, additional
					classes are optional and can be freely defined.
				</Typography>
			</Box>
			{dataCategories && (
				<TrainingDataTable
					dataCategories={dataCategories}
					pixelCount={pixelCount}
					draw={draw}
					maxClasses={maxClasses}
					handleDraw={(classObj) => handleDraw(classObj)}
					handleDeletePolygons={handleClassesDialog}
					showTrainingData={showTrainingData}
					setShowTrainingData={setShowTrainingData}
					hasEdit={isOnSwedishApp}
					setActiveEditDataCategory={handleEditDataCategory}
				/>
			)}
			<AddTrainingClassesDialog
				open={isDialogOpen}
				onClose={handleOnCloseDialog}
				activeEditDataCategory={activeEditDataCategory}
			/>
		</>
	)
}

export default TrainingDataHeader
