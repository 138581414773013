import React, {
	FC,
	ReactNode,
	createContext,
	useState,
	useContext,
	useEffect,
} from 'react'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { FeatureWithProps } from '../regions/types'
import { ProjectType } from '../app-screen/types'
import { AppContext } from '../app-screen/AppContextProvider'
import { RegionsContext } from '../regions/RegionsContextProvider'

interface ViewProjectContextValues {
	state: {
		currentProject: ProjectType | undefined
	}
}
interface Props {
	children: ReactNode
	projectId: string
	isExtent?: boolean
}

type Context = ViewProjectContextValues

export const ViewProjectContext = createContext<Context>(
	null as unknown as Context,
)

const ViewProjectContextProvider: FC<Props> = ({
	children,
	projectId,
	isExtent,
}) => {
	const {
		state: { allProjects },
	} = useContext(AppContext)

	const {
		state: { regions },
		actions: { handleGetRegions, setActiveRegion },
	} = useContext(RegionsContext)

	const { enqueueSnackbar } = useSnackbar()
	const history = useHistory()

	const [currentProject, setCurrentProject] = useState<ProjectType | undefined>(
		undefined,
	)

	useEffect(() => {
		if (allProjects && regions) {
			const project = allProjects.find(
				(localProject: ProjectType) => localProject.id === Number(projectId),
			)
			const region = regions.features.find(
				(feature: FeatureWithProps) =>
					feature.properties.regionId ===
					project?.region_geometry.properties.id,
			)
			if (!(project && region)) {
				enqueueSnackbar('The project does not exist', {
					variant: 'error',
					autoHideDuration: 8000,
				})
				history.push('/')
				return
			}
			if (!isExtent && project?.preprocessing_status !== 'COMPLETED') {
				enqueueSnackbar(
					'The project has not finished processing or it failed.',
					{
						variant: 'warning',
					},
				)
				history.push('/')
				return
			}

			if (project.last_product && project.last_product?.status === 'RUNNING') {
				enqueueSnackbar('The product is currently running.', {
					variant: 'warning',
					autoHideDuration: 8000,
				})
				history.push('/')
				return
			}

			setCurrentProject(project)
			setActiveRegion(region)
		}
	}, [
		allProjects,
		regions,
		enqueueSnackbar,
		projectId,
		history,
		setActiveRegion,
		isExtent,
	])

	useEffect(() => {
		void handleGetRegions()
	}, [handleGetRegions])

	return (
		<ViewProjectContext.Provider
			value={{
				state: {
					currentProject,
				},
			}}
		>
			{children}
		</ViewProjectContext.Provider>
	)
}
export default ViewProjectContextProvider
