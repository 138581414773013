import React, { FC, useContext, useState } from 'react'
import {
	Box,
	Button,
	makeStyles,
	Typography,
	useTheme,
	Chip,
	Dialog,
} from '@material-ui/core'
import { DeleteForever, Delete } from '@material-ui/icons'
import { IMikeTheme } from '@dhi/react-components-lab'
import { ViewMode } from '@nebula.gl/edit-modes'
import { DrawFeatureWithProps, DataClass } from './types'
import { TrainingDataContext } from './TrainingDataContextProvider'
import DeletePopup from '../dashboard/DeletePopup'
import { AppContext, PortalName } from '../app-screen/AppContextProvider'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	deleteButton: {
		backgroundColor: theme.palette.background.paper,
		color: `${theme.palette.error.main}`,
		'&:hover': {
			backgroundColor: `${theme.palette.error.light}0A`,
		},
	},
	trainingDataBox: {
		borderRadius: 4,
		minWidth: 24,
		margin: '0 1px',
		textAlign: 'center',
	},
	trainingDataBoxText: {
		color: theme.palette.background.paper,
	},
	deleteButtonBox: {
		borderLeft: `1px solid ${theme.palette.divider}`,
	},
	icon: {
		width: 24,
		height: 24,
	},
	darkerBorder: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	lighterBorder: {
		borderBottom: `1px solid ${String(theme.palette.grey[50])}`,
	},
	chipDeleteIcon: {
		margin: 1,
		'& .MuiChip-deleteIcon': {
			color: 'rgba(9, 51, 75, 0.6)',
			opacity: 0.8,
			'&:hover': {
				color: 'rgba(9, 51, 75, 0.8)',
			},
		},
		'& .MuiChip-label': {
			height: 17,
		},
		'& .MuiChip-sizeSmall': {
			minWidth: 24,
		},
	},
	deleteAllLink: {
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
}))
const TrainingDataContent: FC = () => {
	const theme = useTheme()
	const {
		state: { dataCategories, trainingFeatureColl, selectedDrawFeature },
		actions: {
			handleDeleteAllTrainingData,
			setSelectedDrawFeature,
			handleRemoveTrainingData,
		},
	} = useContext(TrainingDataContext)
	const {
		state: { portalName },
	} = useContext(AppContext)
	const [showDialog, setShowDialog] = useState<boolean | undefined>(false)
	const onDeleteAllPolygons = () => {
		setShowDialog(true)
	}

	const handleConfirmDelete = () => {
		void handleDeleteAllTrainingData()
		setShowDialog(false)
	}
	const isOnSwedishSav = portalName === PortalName.Sweden
	const classes = useStyles()
	return (
		<>
			{showDialog && (
				<DeletePopup
					handleClose={() => setShowDialog(false)}
					popupContent="Are you sure you want to delete all training data?"
					handleDelete={handleConfirmDelete}
				/>
			)}

			<Box width={1} className={classes.darkerBorder} />
			<Box display="flex" flexDirection="column">
				<Box
					display="flex"
					flexWrap="wrap"
					maxHeight={250}
					style={{ overflowY: 'auto' }}
					my={1}
				>
					<Box width={1} justifyContent="center">
						{(!trainingFeatureColl ||
							trainingFeatureColl?.features.length === 0) && (
							<Typography align="center" variant="body2">
								No training data available
							</Typography>
						)}
					</Box>
					{isOnSwedishSav &&
						![0, undefined].includes(trainingFeatureColl?.features.length) && (
							<Box width={1} display="flex" justifyContent="flex-end">
								<Typography
									className={classes.deleteAllLink}
									variant="body2"
									onClick={onDeleteAllPolygons}
								>
									Delete all polygons
								</Typography>
							</Box>
						)}
					{dataCategories?.map(({ className }: DataClass, i: number) => (
						<Box
							// eslint-disable-next-line react/no-array-index-key
							key={`class-${String(className)}-${i}`}
							width={1}
							display="flex"
							flexWrap="wrap"
							m={0.2}
						>
							{trainingFeatureColl?.features.map(
								(data: DrawFeatureWithProps) => {
									const isSelectedItem =
										selectedDrawFeature?.properties.id === data.properties.id

									return (
										data.properties.className === className && (
											<Chip
												key={`item-class-${data.properties.className}-${data.properties.id}`}
												size="small"
												label={data.properties.id}
												className={classes.chipDeleteIcon}
												style={{
													backgroundColor: isSelectedItem
														? data.properties.classColor
														: `${String(data.properties.classColor)}80`,
												}}
												onClick={() =>
													selectedDrawFeature?.properties.id ===
													data.properties.id
														? setSelectedDrawFeature(undefined)
														: setSelectedDrawFeature(data)
												}
												onDelete={
													isSelectedItem
														? () =>
																void handleRemoveTrainingData({
																	geometryId: data.properties.id,
																})
														: undefined
												}
											/>
										)
									)
								},
							)}
						</Box>
					))}
				</Box>
			</Box>
		</>
	)
}

export default TrainingDataContent
