import { useLocalStorage } from '@dhi/react-components-lab'
import React, { FC, ReactNode, createContext, useState, useEffect } from 'react'

interface DarkModeContextValues {
	state: {
		isDarkMode: boolean
	}
	actions: {
		setIsDarkMode: (s: boolean) => void
	}
}

interface Props {
	children: ReactNode
}

type Context = DarkModeContextValues
export const DarkModeContext = createContext<Context>(
	null as unknown as Context,
)

const DarkModeContextProvider: FC<Props> = ({ children }) => {
	const [isSessionDarkMode, setIsSessionDarkMode] =
		useLocalStorage('isDarkMode')

	const [isDarkMode, setIsDarkMode] = useState(
		isSessionDarkMode !== undefined
			? isSessionDarkMode === 'yes'
			: window.matchMedia &&
					window.matchMedia('(prefers-color-scheme: dark)').matches,
	)
	useEffect(() => {
		if (isDarkMode) {
			setIsSessionDarkMode('yes')
		} else {
			setIsSessionDarkMode('no')
		}
	}, [isDarkMode, setIsSessionDarkMode])

	return (
		<DarkModeContext.Provider
			value={{
				state: {
					isDarkMode,
				},
				actions: {
					setIsDarkMode,
				},
			}}
		>
			{children}
		</DarkModeContext.Provider>
	)
}
export default DarkModeContextProvider
