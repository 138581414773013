/**
 * The colors from DHI Official Guidelines
 */
export default {
	BRANDBLUE_X_LIGHT: '#CFE6EB',
	BRANDBLUE_LIGHT: '#93C4D4',
	BRANDBLUE_DEFAULT: '#0B4566',
	BRANDBLUE_DARK: '#09334B',

	ACTIONBLUE_X_LIGHT: '#C9ECFB',
	ACTIONBLUE_LIGHT: '#80D2F6',
	ACTIONBLUE_DEFAULT: '#00A4EC',
	ACTIONBLUE_DARK: '#0076C8',

	GREEN_X_LIGHT: '#D8EFD4',
	GREEN_LIGHT: '#A5D99B',
	GREEN_DEFAULT: '#61C051',
	GREEN_DARK: '#188D01',

	YELLOW_X_LIGHT: '#FFF6BD',
	YELLOW_LIGHT: '#FFED7B',
	YELLOW_DEFAULT: '#FFE300',
	YELLOW_DARK: '#FFC20A',

	PINK_X_LIGHT: '#FFD0DD',
	PINK_LIGHT: '#FF91AF',
	PINK_DEFAULT: '#FD3F75',
	PINK_DARK: '#D40E58',

	GREY50: '#F2F5F7',
	GREY100: '#DBE4E9',
	GREY200: '#CFDBE2',
	GREY300: '#B1C5D0',
	GREY400: '#9DB6C2',
	GREY500: '#86A2B3',
	GREY600: '#557A8F',
	GREY700: '#3A5768',
	GREY800: '#243A46',
	GREY900: '#15252E',

	WHITE: '#FFFFFF',
	BLACK: '#121212',
}
