import React, { FC, ReactNode, useContext, useEffect, useMemo } from 'react'
import { BitmapLayer, TileLayer } from 'deck.gl'
import { OrthoContext } from 'sav-features/orthophoto/OrthoContextProvider'

const orthoDataset = {
	imageLayer: 'Ortofoto_0.16',
	textLayer: 'Ortofoto_0.16_fs',
}

const wmsUsername = process.env.REACT_APP_SE_WMS_USERNAME
const wmsPassword = process.env.REACT_APP_SE_WMS_PASSWORD

interface Props {
	children: ReactNode
}
const OrthoWrapper: FC<Props> = ({ children }) => {
	const {
		state: { showOrtho },
		layers: { setOrthoLayers },
	} = useContext(OrthoContext)

	const getOrthoLayer = (layerName: string) =>
		new TileLayer({
			id: `ortho-dataset-${layerName}`,
			data: undefined,
			minZoom: 0,
			pickable: true,
			maxZoom: 19,
			tileSize: 256,
			renderSubLayers: (props) => {
				const {
					bbox: { west, south, east, north },
				} = props.tile
				const bbox = `${String(south)},${String(west)},${String(
					north,
				)},${String(east)}`
				const url = `https://maps.lantmateriet.se/ortofoto/wms/v1.3?request=GetMap
					&width=256
					&height=256
					&crs=EPSG:4326
					&bbox=${bbox}
					&format=image/png
					&layers=${layerName}
					&service=WMS
					&version=1.3.0`
				const token = window.btoa(
					`${String(wmsUsername)}:${String(wmsPassword)}`,
				)
				const imagePromise = fetch(url, {
					headers: {
						Accept: 'image/png',
						Authorization: `Basic ${token}`,
					},
				})
					.then((res) => {
						if (!res.ok) {
							throw new Error(`HTTP error: ${res.status}`)
						}
						return res.blob()
					})
					.then((blobRes) => createImageBitmap(blobRes))
					.catch((error) => {
						console.error('Error loading image:', error)
						return new BitmapLayer(props, {
							data: null,
							image: null,
							bounds: [west, south, east, north],
						})
					})
				return new BitmapLayer(props, {
					data: null,
					image: imagePromise,
					bounds: [west, south, east, north],
				})
			},
		})

	const orthoLayer = useMemo(
		() =>
			showOrtho
				? [
						getOrthoLayer(orthoDataset.imageLayer),
						getOrthoLayer(orthoDataset.textLayer),
				  ]
				: [undefined, undefined],
		[showOrtho],
	)

	useEffect(() => {
		setOrthoLayers(orthoLayer)
	}, [orthoLayer, setOrthoLayers])

	return <>{children}</>
}

export default OrthoWrapper
