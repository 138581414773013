import { TypographyOptions } from '@material-ui/core/styles/createTypography'
import { HTML_FONT_SIZE, FONT_SIZE, FONT_FAMILY } from './types'

const mikeTypography: TypographyOptions = {
	htmlFontSize: HTML_FONT_SIZE,
	fontSize: FONT_SIZE,
	fontFamily: FONT_FAMILY,
}

export default mikeTypography
