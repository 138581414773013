import axios from 'axios'

const isDevEnv = process.env.NODE_ENV === 'development'

const DEV_DB = process.env.REACT_APP_DATABASE_DEV_URL
const PRO_DB = process.env.REACT_APP_DATABASE_PRO_URL
const DATABASE_URL = isDevEnv ? DEV_DB : PRO_DB

export default async (
	endpoint: string,
	accessToken: string,
	body: object = {},
	headers?: Record<string, string>,
): Promise<any> => {
	const endPoint = `${String(DATABASE_URL)}${endpoint}`

	const response = await axios.post(endPoint, body, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			'Content-Type': 'application/json',
			...headers,
		},
	})

	const { data } = response || {}
	return data
}
