import React, { FC, ReactNode } from 'react'
import {
	Typography,
	Box,
	Button,
	Dialog,
	DialogActions,
	makeStyles,
} from '@material-ui/core'
import { IMikeTheme } from '@dhi/react-components-lab'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	dangerButton: {
		backgroundColor: theme.palette.error?.main,
		'&:hover': {
			backgroundColor: theme.palette.error?.dark,
		},
	},
	dangerText: {
		color: theme.palette.error?.main,
	},
	projectNumber: {
		fontSize: 24,
		color: theme.palette.error?.main,
	},
}))

interface Props {
	handleDelete: () => void
	popupContent: string | ReactNode
	handleClose: () => void
}

const DeletePopup: FC<Props> = ({
	handleDelete,
	popupContent,
	handleClose,
}) => {
	const classes = useStyles()

	return (
		<Dialog open onClose={handleClose}>
			<Box p={2}>
				<Typography variant="h3" gutterBottom>
					<span className={classes.projectNumber}>{popupContent}</span>
				</Typography>
				<Box my={4}>
					<Typography variant="body1">
						This action is{' '}
						<span className={classes.dangerText}>irreversible</span>. When you
						are absolutely certain, click the delete button below.
					</Typography>
				</Box>
				<DialogActions>
					<Box mr={0.5}>
						<Button size="small" variant="outlined" onClick={handleClose}>
							Cancel
						</Button>
					</Box>
					<Box ml={0.5}>
						<Button
							className={classes.dangerButton}
							size="small"
							variant="contained"
							onClick={handleDelete}
						>
							Delete
						</Button>
					</Box>
				</DialogActions>
			</Box>
		</Dialog>
	)
}

export default DeletePopup
