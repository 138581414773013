/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	errorType: {
		fontSize: 72,
		fontWeight: 800,
		color: theme.palette.error.main,
	},
	errorMessage: {
		fontSize: 24,
		fontWeight: 400,
		color: theme.palette.primary.main,
	},
	actionText: {
		fontSize: 14,
		fontWeight: 400,
		color: theme.palette.primary.main,
	},
	actionTextLink: {
		color: theme.palette.secondary.main,
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	errorOverlay: {
		position: 'fixed',
		width: '100%',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: 'rgba(255, 255, 255, 0.8)',
	},
	divider: {
		borderTop: `1px solid ${theme.palette.divider}`,
		width: 140,
	},
}))
const ErrorScreen = ({ type, error, message }) => {
	const history = useHistory()
	const classes = useStyles()

	const reloadPage = () => location.reload() // eslint-disable-line no-restricted-globals

	return (
		<Box
			display="flex"
			mt="5%"
			justifyContent="center"
			alignItems="center"
			height="70vh"
		>
			<Box display="flex" flexDirection="column" alignItems="center">
				<Box>
					<Typography className={classes.errorType}>{error}</Typography>
				</Box>
				<Box className={classes.divider} />
				<Box mt={2}>
					<Typography className={classes.errorMessage}>{message}</Typography>
				</Box>
				<Box mt={5}>
					{type === 'http' ? (
						<Typography className={classes.actionText}>
							{'Check out the '}
							<span
								className={classes.actionTextLink}
								onClick={() => history.push('/')}
							>
								dashboard
							</span>
							{' instead'}
						</Typography>
					) : (
						<Typography className={classes.actionText}>
							<span className={classes.actionTextLink} onClick={reloadPage}>
								Reload the page
							</span>
						</Typography>
					)}
				</Box>
			</Box>
		</Box>
	)
}

export default ErrorScreen
