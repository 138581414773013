import axios from 'axios'

const isDevEnv = process.env.NODE_ENV === 'development'

const DEV_DB = process.env.REACT_APP_DATABASE_DEV_URL
const PRO_DB = process.env.REACT_APP_DATABASE_PRO_URL
const DATABASE_URL = isDevEnv ? DEV_DB : PRO_DB

export default async (
	endpoint: string,
	accessToken: string,
	params: object = {},
	additionalConfig: object = {},
): Promise<any> => {
	const endPoint = `${String(DATABASE_URL)}${endpoint}`
	const res = await axios.get(endPoint, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			'Content-Type': 'application/json',
		},
		...additionalConfig,
		params,
	})

	return res.data
}
