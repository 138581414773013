import React, { useState, useEffect, FC, useMemo, useCallback } from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import Slider from 'sav-components/Slider'
import moment from 'moment'
import clsx from 'clsx'
import { useTitleStyles } from '../create-project/styles'
import { FeatureWithProps } from '../regions/types'
import { ReducedDatesYears, DateWithClouds } from './types'
import { filterByCloudProcentage, getReducedDates } from './utils'
import DatePreviewImage from './DatePreviewImage'

const DEFAULT_SLIDER_VAL = 100

const buttonStyle = makeStyles((theme) => ({
	button: {
		backgroundColor: theme.palette.grey[50],
		borderRadius: 4,
		color: theme.palette.primary.main,
		cursor: 'pointer',
		fontWeight: 'bold',
		minWidth: 42,
		margin: 2,
		padding: '0px 7px',
		boxSizing: 'border-box',
		textAlign: 'center',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.background.paper,
		},
	},
	semiButton: {
		backgroundColor: theme.palette.grey[50],
		borderRadius: 4,
		color: theme.palette.primary.main,
		cursor: 'pointer',
		fontWeight: 'bold',
		minWidth: 42,
		margin: 2,
		padding: '0px 7px',
		boxSizing: 'border-box',
		textAlign: 'center',
		'&:hover': {
			backgroundColor: theme.palette.grey[100],
		},
	},
	active: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.background.paper,
	},
	semiActive: {
		backgroundColor: theme.palette.grey[100],
		color: theme.palette.primary.main,
	},
	quarters: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.main,
	},
	dateHover: {
		'&:hover': {
			'& .MuiTypography-root': {
				color: theme.palette.background.paper,
			},
		},
	},
}))

interface Props {
	dates: DateWithClouds[] | undefined
	activeDate: string | undefined
	activeRegion: FeatureWithProps | undefined
	datePreviewImage?: string | string[] | undefined
	isDatePreviewLoading: boolean
	handleGetPreview: (d: string) => Promise<void> | void
	setActiveDate: (r: string | undefined) => void
	setDatePreviewImage?: (d: string | string[] | undefined) => void
}

const SelectDate: FC<Props> = ({
	datePreviewImage,
	isDatePreviewLoading,
	dates,
	activeDate,
	activeRegion,
	setActiveDate,
	handleGetPreview,
	setDatePreviewImage,
}) => {
	const buttonClass = buttonStyle()
	const classes = useTitleStyles()

	const [persistentDates, setPersistentDates] = useState<
		undefined | ReducedDatesYears
	>(undefined)
	const [localDates, setLocalDates] = useState<undefined | ReducedDatesYears>(
		undefined,
	)
	const [selectedMonth, setSelectedMonth] = useState<string | undefined>(
		undefined,
	)
	const [selectedYear, setSelectedYear] = useState<string | undefined>(
		undefined,
	)
	const [cloudValue, setCloudValue] = useState<number>(100)

	const yearsArray = useMemo(
		() => localDates && Object.keys(localDates),
		[localDates],
	)
	const monthsArray = useMemo(
		() => localDates && selectedYear && Object.keys(localDates[selectedYear]),
		[localDates, selectedYear],
	)
	const daysArray = useMemo(
		() =>
			localDates &&
			selectedYear &&
			selectedMonth &&
			localDates[selectedYear][selectedMonth],
		[localDates, selectedYear, selectedMonth],
	)

	const setActiveAndPreviewUndefined = useCallback(() => {
		setDatePreviewImage?.(undefined)
		setActiveDate(undefined)
	}, [setActiveDate, setDatePreviewImage])

	const handleChange = useCallback(
		(val: number) => {
			setCloudValue(val)

			if (persistentDates) {
				const formattedDates = filterByCloudProcentage({
					dates: persistentDates,
					cloudProcentage: val,
				})
				setLocalDates(formattedDates)
			}
		},
		[persistentDates],
	)

	const handleActiveDate = (date: string) => {
		setActiveDate(date)
		void handleGetPreview(date)
	}

	const onSelectYear = (year: string) => {
		setActiveAndPreviewUndefined()
		setSelectedMonth(undefined)
		return year === selectedYear
			? setSelectedYear(undefined)
			: setSelectedYear(year)
	}

	const onSelectMonth = (month: string) => {
		setActiveAndPreviewUndefined()

		return month === selectedMonth
			? setSelectedMonth(undefined)
			: setSelectedMonth(month)
	}

	useEffect(() => {
		handleChange(cloudValue)
	}, [persistentDates, handleChange, cloudValue])

	useEffect(() => {
		// dates
		setActiveAndPreviewUndefined()
		setSelectedMonth(undefined)
		setSelectedYear(undefined)
		const localYearsArray = dates && getReducedDates({ dates })
		if (localYearsArray) {
			setPersistentDates(localYearsArray)
			setLocalDates(localYearsArray)
		}
	}, [dates, activeRegion, setActiveAndPreviewUndefined])

	return (
		<Box>
			<Box width={1} pb={1}>
				<Typography variant="body2">
					Set the cloud threshold (max. percentage of cloud coverage per
					satellite image) and select the target date for the mapping.
				</Typography>
			</Box>
			<Box pb={1}>
				<Slider
					defaultValue={DEFAULT_SLIDER_VAL}
					title="Clouds:"
					min={0}
					max={100}
					step={1}
					unit="%"
					getValueCommitted={(val: number | number[]) =>
						setCloudValue(val as number)
					}
				/>
			</Box>
			{yearsArray && (
				<>
					<Typography className={classes.subtitle} variant="body2" gutterBottom>
						Select year:
					</Typography>
					<Box width={1} pb={selectedYear && 1} display="flex" flexWrap="wrap">
						{yearsArray.map((year) => (
							<Box
								key={`year-${year}`}
								className={clsx(
									buttonClass.semiButton,
									year === selectedYear && buttonClass.semiActive,
								)}
								style={{ minWidth: '23.5%' }}
								onClick={() => onSelectYear(year)}
							>
								<Typography variant="body2">{year}</Typography>
							</Box>
						))}
					</Box>
				</>
			)}

			{monthsArray && monthsArray.length > 0 && (
				<>
					<Typography className={classes.subtitle} variant="body2" gutterBottom>
						Select month:
					</Typography>
					<Box width={1} pb={selectedMonth && 1} display="flex" flexWrap="wrap">
						{monthsArray.map((month) => (
							<Box
								key={`month-${month}`}
								className={clsx(
									buttonClass.semiButton,
									month === selectedMonth && buttonClass.semiActive,
								)}
								style={{ minWidth: '23.5%' }}
								onClick={() => onSelectMonth(month)}
							>
								<Typography variant="body2">{month}</Typography>
							</Box>
						))}
					</Box>
				</>
			)}
			{daysArray && daysArray.length > 0 && (
				<>
					<Typography className={classes.subtitle} variant="body2" gutterBottom>
						Select date:
					</Typography>
					<Box width={1} display="flex" flexWrap="wrap">
						{daysArray.map(({ date, cloud_coverage }: DateWithClouds) => (
							<Box
								key={`${String(date)}-${String(cloud_coverage)}`}
								className={clsx(
									buttonClass.button,
									buttonClass.dateHover,
									date === activeDate && buttonClass.active,
								)}
								style={{ minWidth: '23.5%' }}
								onClick={() => handleActiveDate(date)}
							>
								<Typography
									variant="body2"
									className={clsx(date === activeDate && buttonClass.active)}
								>
									{moment(date).format('D')}
								</Typography>
							</Box>
						))}
					</Box>
				</>
			)}
			<DatePreviewImage
				isLoading={isDatePreviewLoading}
				datePreviewImage={datePreviewImage}
			/>
		</Box>
	)
}

export default SelectDate
