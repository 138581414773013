import React, {
	FC,
	ReactNode,
	createContext,
	useState,
	useCallback,
	useContext,
} from 'react'
import getData from 'sav-utils/getData'
import { useSnackbar } from 'notistack'
import { useAuth0 } from '@auth0/auth0-react'
import { GeoJsonLayer } from 'deck.gl'
import { AppContext } from '../app-screen/AppContextProvider'
import {
	FeatureCollectionWithProps,
	GetRegionsResponseItem,
	FeatureWithProps,
} from './types'

interface RegionsContextValues {
	state: {
		regions: FeatureCollectionWithProps | undefined
		activeRegion: FeatureWithProps | undefined
	}
	actions: {
		setRegions: (r: FeatureCollectionWithProps | undefined) => void
		setActiveRegion: (r: FeatureWithProps | undefined) => void
		handleGetRegions: () => Promise<void>
	}
	layers: {
		activeRegionLayer: GeoJsonLayer<any>
	}
}

interface Props {
	children: ReactNode
	isExtent?: boolean
}

type Context = RegionsContextValues
export const RegionsContext = createContext<Context>(null as unknown as Context)

const RegionsContextProvider: FC<Props> = ({ children, isExtent }) => {
	const {
		actions: { setIsLoading },
	} = useContext(AppContext)
	const { enqueueSnackbar } = useSnackbar()
	const { getAccessTokenSilently } = useAuth0()
	const [regions, setRegions] = useState<
		FeatureCollectionWithProps | undefined
	>(undefined)
	const [activeRegion, setActiveRegion] = useState<
		FeatureWithProps | undefined
	>(undefined)

	const handleGetRegions = useCallback(async () => {
		try {
			setIsLoading(true)
			const token = await getAccessTokenSilently()
			if (token) {
				const response = await getData('/get-regions', token)
				const localRegions = {
					type: 'FeatureCollection' as const,
					features: response.regions.map((region: GetRegionsResponseItem) => ({
						type: 'Feature' as const,
						geometry: region.geometry,
						properties: {
							swedishName: region.name_swedish || region.name_local,
							name: region.name,
							regionId: region.id,
						},
					})),
				} as FeatureCollectionWithProps
				setRegions(localRegions)
				enqueueSnackbar(`Regions added successfully.`, {
					variant: 'success',
					preventDuplicate: true,
					autoHideDuration: 8000,
				})
			}
		} catch (err) {
			enqueueSnackbar(`There was a problem when retrieving the regions.`, {
				variant: 'error',
				autoHideDuration: 8000,
			})
		} finally {
			setIsLoading(false)
		}
	}, [
		getAccessTokenSilently,
		enqueueSnackbar,
		setRegions,
		setIsLoading,
		isExtent,
	])

	const activeRegionLayer = new GeoJsonLayer({
		id: 'se-sav-current-region',
		data: activeRegion ?? undefined,
		stroked: true,
		filled: true,
		pickable: true,
		lineWidthMinPixels: 1,
		getLineWidth: 1,
		lineWidthUnits: 'pixels',
		getLineColor: () => [16, 151, 218, 255],
		getFillColor: () => [0, 0, 0, 0],
	})

	return (
		<RegionsContext.Provider
			value={{
				state: {
					regions,
					activeRegion,
				},
				actions: {
					setRegions,
					handleGetRegions,
					setActiveRegion,
				},
				layers: {
					activeRegionLayer,
				},
			}}
		>
			{children}
		</RegionsContext.Provider>
	)
}
export default RegionsContextProvider
