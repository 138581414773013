import React, { FC } from 'react'
import { Box, Grid, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { IMikeTheme } from '@dhi/react-components-lab'
import { FeatureWithProps, FeatureCollectionWithProps } from './types'

const useStyles = makeStyles<IMikeTheme>((theme) => ({
	itemWrapper: {
		cursor: 'pointer',
	},
	regionText: {
		fontSize: 11,
	},
	regionCheckbox: {
		border: `1px solid ${theme.palette.primary.main}`,
	},
}))

interface Props {
	regions: FeatureCollectionWithProps | undefined
	activeRegion: FeatureWithProps | undefined
	onSetActiveRegion: (r: FeatureWithProps | undefined) => void
}

const SelectRegion: FC<Props> = ({
	regions,
	activeRegion,
	onSetActiveRegion,
}) => {
	const classes = useStyles()
	const theme = useTheme()
	const primary = theme.palette.primary.main

	return (
		<Box>
			<Box width={1} pb={1}>
				<Typography variant="body2">
					Select a region on the map and proceed to the next step.
				</Typography>
			</Box>
			<Box width={1} display="flex" flexWrap="wrap">
				{regions?.features?.map((feature: FeatureWithProps) => (
					<Box
						key={feature.properties?.name}
						width="50%"
						className={classes.itemWrapper}
						onClick={() => onSetActiveRegion(feature)}
					>
						<Grid container spacing={1}>
							<Grid item xs={1}>
								<Box
									width={7}
									height={7}
									mt="3px"
									className={classes.regionCheckbox}
									borderRadius="50%"
									style={{
										backgroundColor:
											activeRegion?.properties.regionId ===
											feature.properties.regionId
												? primary
												: 'white',
									}}
								/>
							</Grid>
							<Grid item xs={11}>
								<Typography variant="body2" className={classes.regionText}>
									{feature.properties?.swedishName}
								</Typography>
							</Grid>
						</Grid>
					</Box>
				))}
			</Box>
		</Box>
	)
}

export default SelectRegion
