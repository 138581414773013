import { createTheme } from '@material-ui/core/styles'

// #region Local imports
import mikeTypography from './mikeTypography'
import getPalette from './getPallete'
import getOverrides from './getOverrides'
import mikeComponentsProps from './mikeComponentsProps'
// #endregion

export const getTheme = (type) =>
	createTheme({
		typography: mikeTypography,
		palette: getPalette(type),
		overrides: getOverrides(type),
		props: mikeComponentsProps,
	})

export default getTheme
