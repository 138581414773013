import React from 'react'
import ReactDOM from 'react-dom'
import 'typeface-roboto'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import App from './App'

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: 'https://57f52fcaa59544e5bf3e9c378bd87943@o377546.ingest.sentry.io/6308338',
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
	})
}

ReactDOM.render(<App />, document.getElementById('root'))
