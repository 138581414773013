import React, {
	FC,
	ReactNode,
	createContext,
	useState,
	useContext,
	useCallback,
} from 'react'
import { TileLayer } from '@deck.gl/geo-layers'
import { TileLayerProps } from '@deck.gl/geo-layers/tile-layer/tile-layer'
import moment from 'moment'
import { ProjectType } from '../app-screen/types'
import useRasterLayer from '../map/hooks/useRasterLayer'
import { TrainingDataContext } from '../training-data/TrainingDataContextProvider'

interface HabitatLayerContextValues {
	state: {
		showHabitatLayer: boolean
		habitatLayerOpacity: number
	}
	actions: {
		setShowHabitatLayer: (s: boolean) => void
		setHabitatLayerOpacity: (n: number) => void
	}
	layers: {
		habitatLayer: TileLayer<unknown, TileLayerProps<unknown>> | undefined
	}
}

interface Props {
	children: ReactNode
	currentProject: ProjectType | undefined
	isExtent?: boolean
}

type Context = HabitatLayerContextValues
export const HabitatLayerContext = createContext<Context>(
	null as unknown as Context,
)

const TERRACOTTA_URL = process.env.REACT_APP_TERACCOTTA_URL

const HabitatLayerContextProvider: FC<Props> = ({
	children,
	currentProject,
	isExtent = false,
}) => {
	const {
		state: { dataCategories },
	} = useContext(TrainingDataContext)

	const [showHabitatLayer, setShowHabitatLayer] = useState(true)
	const [habitatLayerOpacity, setHabitatLayerOpacity] = useState(100)

	const showSAVTile = useCallback(() => {
		if (currentProject?.last_product && dataCategories) {
			const projectId = currentProject.id
			const productId = currentProject.last_product.product_id
			const colors = dataCategories.reduce(
				(acc, item) => ({
					...acc,
					[item.classId + (isExtent ? 1 : 0)]: item.classColor.split('#')[1],
				}),
				{},
			)
			const formatDate = moment(currentProject.acquisition_date).format(
				'YYYYMMDD',
			)
			if (isExtent) {
				return `${String(TERRACOTTA_URL)}/singleband/${String(
					productId,
				)}/${formatDate}/{z}/{x}/{y}.png?colormap=explicit&explicit_color_map=${encodeURIComponent(
					JSON.stringify(colors),
				)}`
			}

			return `${String(TERRACOTTA_URL)}/singleband/${String(
				currentProject.project_deployment,
			)}/product/${String(projectId)}/${String(
				productId,
			)}/null/B01/{z}/{x}/{y}.png?colormap=explicit&explicit_color_map=${encodeURIComponent(
				JSON.stringify(colors),
			)}`
		}
		return undefined
	}, [currentProject, dataCategories])

	const habitatLayer = useRasterLayer({
		id: 'post-classification-terracotta-layer',
		data: showHabitatLayer && currentProject && dataCategories && showSAVTile(),
		opacity: habitatLayerOpacity / 100,
	})

	return (
		<HabitatLayerContext.Provider
			value={{
				state: {
					showHabitatLayer,
					habitatLayerOpacity,
				},
				actions: {
					setShowHabitatLayer,
					setHabitatLayerOpacity,
				},
				layers: {
					habitatLayer,
				},
			}}
		>
			{children}
		</HabitatLayerContext.Provider>
	)
}
export default HabitatLayerContextProvider
