import React, {
	FC,
	ReactNode,
	createContext,
	useState,
	useEffect,
	useCallback,
} from 'react'
import postData from 'sav-utils/postData'
import getData from 'sav-utils/getData'
import { useAuth0 } from '@auth0/auth0-react'
import { Loader } from '@dhi/react-components-lab'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { ProjectType, AddProjectPayload, AddProjectPCPayload } from './types'
// eslint-disable-next-line import/no-cycle

export enum PortalName {
	Sweden = 'sweden',
	Denmark = 'denmark',
	Extent = 'extent',
}

interface AppContextValues {
	state: {
		allProjects: ProjectType[] | undefined
		isLoading: boolean
		portalName: PortalName
	}
	actions: {
		setAllProjects: (p: ProjectType[] | undefined) => void
		fetchProjects: () => Promise<void>
		setIsLoading: (val: boolean) => void
		addProject: (pay: AddProjectPayload) => Promise<boolean>
		addProjectPC: (pay: AddProjectPCPayload) => Promise<boolean>
	}
}

interface Props {
	children: ReactNode
	portalName: PortalName
}

type Context = AppContextValues
export const AppContext = createContext<Context>(null as unknown as Context)

const AppContextProvider: FC<Props> = ({ children, portalName }) => {
	const {
		isAuthenticated,
		getAccessTokenSilently,
		loginWithRedirect,
		isLoading: isAuthLoading,
	} = useAuth0()
	const { enqueueSnackbar } = useSnackbar()
	const history = useHistory()

	const [allProjects, setAllProjects] = useState<ProjectType[] | undefined>()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const fetchProjects = useCallback(async () => {
		try {
			const token = await getAccessTokenSilently()
			if (token) {
				const response = await getData('/get-projects', token)
				setAllProjects(response.projects)
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err.message)
		}
	}, [getAccessTokenSilently])

	const addProject = useCallback(
		async ({
			date,
			deglint,
			cloudMask,
			regionId,
		}: AddProjectPayload): Promise<boolean> => {
			try {
				setIsLoading(true)
				const body = {
					acquisition_date: date,
					preprocessing_settings: {
						deglint,
						apply_cloud_mask: cloudMask,
					},
					region_id: regionId,
				}

				await postData('/add-project', await getAccessTokenSilently(), body)

				enqueueSnackbar(`Project added successfully.`, {
					variant: 'success',
					autoHideDuration: 8000,
				})
				history.push('/')
				return true
			} catch (err) {
				enqueueSnackbar(`Project could not be added.`, {
					variant: 'error',

					autoHideDuration: 8000,
				})
				return false
			} finally {
				setIsLoading(false)
			}
		},
		[getAccessTokenSilently, enqueueSnackbar, history],
	)

	const addProjectPC = useCallback(
		async ({ date, regionId }: AddProjectPCPayload): Promise<boolean> => {
			try {
				setIsLoading(true)
				const body = {
					acquisition_date: `${date}T00:00:00`,
					region_id: regionId,
				}

				await postData('/add-project', await getAccessTokenSilently(), body)

				enqueueSnackbar(`Project added successfully.`, {
					variant: 'success',
					autoHideDuration: 8000,
				})
				history.push('/')
				return true
			} catch (err) {
				enqueueSnackbar(`Project could not be added.`, {
					variant: 'error',

					autoHideDuration: 8000,
				})
				return false
			} finally {
				setIsLoading(false)
			}
		},
		[getAccessTokenSilently, enqueueSnackbar, history],
	)

	useEffect(() => {
		const fetchingData = async () => {
			setIsLoading(true)

			await fetchProjects()
			setIsLoading(false)
		}
		if (isAuthenticated) {
			void fetchingData()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated])
	if (!isAuthenticated && !isAuthLoading) void loginWithRedirect()
	if (!isAuthenticated && !allProjects)
		return <Loader isLoading variant="blocking" />

	return (
		<AppContext.Provider
			value={{
				state: {
					allProjects,
					isLoading,
					portalName,
				},
				actions: {
					setAllProjects,
					fetchProjects,
					setIsLoading,
					addProject,
					addProjectPC,
				},
			}}
		>
			<Loader isLoading={isLoading} variant="topbar" />
			{children}
		</AppContext.Provider>
	)
}
export default AppContextProvider
