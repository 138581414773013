// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/require-default-props */
import React, { CSSProperties, ReactNode, FC } from 'react'
import { Box, Typography, useTheme } from '@material-ui/core'

const mainTitleStyle = {
	fontSize: 20,
	fontWeight: 500,
}

interface Props {
	title: string
	titleColor?: string
	subTitle?: string[]
	subTitleColor?: string
	images?: ReactNode[]
	style?: CSSProperties
}
const SidebarTitle: FC<Props> = ({
	title = 'Current title',
	subTitle = [''],
	images = undefined,
	titleColor,
	subTitleColor,
	style,
}) => {
	const theme = useTheme()
	return (
		<Box
			py={2}
			style={{
				backgroundColor: theme.palette.background.paper,
				...style,
			}}
		>
			<Box
				display="flex"
				flexWrap="nowrap"
				justifyContent="space-between"
				alignItems="center"
			>
				<Box display="flex" alignItems="center">
					<Typography
						style={{
							color: titleColor || theme.palette.text.primary,
							...mainTitleStyle,
						}}
						variant="h2"
					>
						{title}
					</Typography>
				</Box>
				{images && images.length > 0 && (
					<Box display="flex" alignItems="center">
						{images.map((image: ReactNode, i: number) => (
							<span key={`image-${i}`}>{image}</span>
						))}
					</Box>
				)}
			</Box>
			{subTitle.length > 0 && (
				<Box mt={1}>
					{subTitle.map((text: string, index: number) => (
						<Typography
							key={`subtitle-${index}`}
							style={{
								color: subTitleColor || theme.palette.grey[500],
								lineHeight: 'unset',
							}}
							color="secondary"
							variant="subtitle1"
						>
							{text}
						</Typography>
					))}
				</Box>
			)}
		</Box>
	)
}

export default SidebarTitle
