import React, {
	FC,
	useState,
	ReactNode,
	createContext,
	useCallback,
} from 'react'
import { ViewportProps, FlyToInterpolator } from 'react-map-gl'

import { easeCubicInOut } from 'd3-ease'

interface MapContextValues {
	state: {
		isOpticalBasemap: boolean
		viewport: ViewportProps
		mapHeight: number | undefined
		mapWidth: number | undefined
		rasterOpacity: number
	}
	actions: {
		setIsOpticalBasemap: (val: boolean) => void
		setViewport: (v: ViewportProps) => void
		setMapHeight: (h: number | undefined) => void
		setMapWidth: (w: number | undefined) => void
		setRasterOpacity: (o: number) => void
		handleViewport: (v: ViewportProps) => void
	}
}

type Context = MapContextValues

export const MapContext = createContext<Context>(
	null as unknown as MapContextValues,
)

export const defaultViewport: ViewportProps = {
	latitude: 49.94,
	longitude: 27.02,
	zoom: 1.5,
	bearing: 0,
	pitch: 0,
	transitionDuration: 3000,
	transitionInterpolator: new FlyToInterpolator(),
	transitionEasing: easeCubicInOut,
}

interface Props {
	children: ReactNode
}

const MapContextProvider: FC<Props> = ({ children }) => {
	const [isOpticalBasemap, setIsOpticalBasemap] = useState(false)
	const [viewport, setViewport] = useState(defaultViewport)
	const [mapHeight, setMapHeight] = useState<number | undefined>(undefined)
	const [mapWidth, setMapWidth] = useState<number | undefined>(undefined)
	const [rasterOpacity, setRasterOpacity] = useState<number>(100)

	const handleViewport = useCallback(
		(view: Partial<ViewportProps>) => {
			setViewport({
				transitionDuration: 3000,
				transitionInterpolator: new FlyToInterpolator(),
				transitionEasing: easeCubicInOut,
				...view,
			})
		},
		[setViewport],
	)

	return (
		<MapContext.Provider
			value={{
				state: {
					isOpticalBasemap,
					viewport,
					mapHeight,
					mapWidth,
					rasterOpacity,
				},
				actions: {
					setIsOpticalBasemap,
					setViewport,
					setMapHeight,
					setMapWidth,
					setRasterOpacity,
					handleViewport,
				},
			}}
		>
			{children}
		</MapContext.Provider>
	)
}

export default MapContextProvider
