import React, { FC } from 'react'
import RegionsContextProvider from 'sav-features/regions/RegionsContextProvider'
// import MapContextProvider from 'sav-features/map/MapContextProvider'
import ViewProjectContextProvider from 'sav-features/view-project/ViewProjectContextProvider'
import TrainingDataContextProvider from 'sav-features/training-data/TrainingDataContextProvider'
import ViewProject from './ViewProject'

interface Props {
	projectId: string
}

const ViewProjectScreen: FC<Props> = ({ projectId }) => (
	<RegionsContextProvider>
		<ViewProjectContextProvider projectId={projectId}>
			<TrainingDataContextProvider projectId={projectId}>
				<ViewProject />
			</TrainingDataContextProvider>
		</ViewProjectContextProvider>
	</RegionsContextProvider>
)
export default ViewProjectScreen
