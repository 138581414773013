import React, {
	FC,
	ReactNode,
	createContext,
	useState,
	useCallback,
	useContext,
} from 'react'
import { useSnackbar } from 'notistack'
import { ConfusionMatrixContext } from '../confusion-matrix/ConfusionMatrixContextProvider'
import { ValidationData, ValidationDataFeature } from './types'
import { TrainingDataContext } from '../training-data/TrainingDataContextProvider'

interface ValidationContextValues {
	state: {
		showValidation: boolean
		validationData: ValidationData | undefined
		isValidationLoading: boolean
	}
	actions: {
		setShowValidation: (b: boolean) => void
		setValidationData: (v: ValidationData | undefined) => void
		setIsValidationLoading: (l: boolean) => void
		handleUploadValidation: (
			target: EventTarget & HTMLInputElement,
		) => Promise<void>
		handleShowValidation: (b: boolean) => void
	}
}

interface Props {
	children: ReactNode
}

type Context = ValidationContextValues
export const ValidationContext = createContext<Context>(
	null as unknown as Context,
)

const ValidationContextProvider: FC<Props> = ({ children }) => {
	const { enqueueSnackbar } = useSnackbar()

	const [showValidation, setShowValidation] = useState(false)
	const [validationData, setValidationData] = useState<
		ValidationData | undefined
	>(undefined)
	const [isValidationLoading, setIsValidationLoading] = useState<boolean>(false)

	const {
		actions: { handleConfusionMatrix },
	} = useContext(ConfusionMatrixContext)
	const {
		actions: { handleUploadZipFile },
	} = useContext(TrainingDataContext)

	const handleUploadValidation = useCallback(
		async (target: EventTarget & HTMLInputElement) => {
			try {
				setIsValidationLoading(true)
				const { files } = target
				if (files) {
					const localFormattedFeatures = await handleUploadZipFile<
						ValidationDataFeature[]
					>({
						files,
						route: '/upload-validation-data',
					})

					if (localFormattedFeatures)
						setValidationData({
							type: 'FeatureCollection' as const,
							crs: { type: 'name', properties: { name: 'EPSG:4326' } },
							features: [...localFormattedFeatures] as ValidationDataFeature[],
						})
					enqueueSnackbar('Fetching the confusion matrix...', {
						variant: 'info',
						autoHideDuration: 8000,
					})
					await handleConfusionMatrix()
					setShowValidation(true)
				}
			} finally {
				setIsValidationLoading(false)
			}
		},
		[
			enqueueSnackbar,
			handleConfusionMatrix,
			setValidationData,
			handleUploadZipFile,
		],
	)

	const handleShowValidation = (val: boolean) =>
		validationData
			? setShowValidation(val)
			: enqueueSnackbar(
					'Please upload validation data to unlock this feature.',
					{
						variant: 'info',
						autoHideDuration: 8000,
					},
			  )
	return (
		<ValidationContext.Provider
			value={{
				state: {
					showValidation,
					validationData,
					isValidationLoading,
				},
				actions: {
					setShowValidation,
					setValidationData,
					setIsValidationLoading,
					handleUploadValidation,
					handleShowValidation,
				},
			}}
		>
			{children}
		</ValidationContext.Provider>
	)
}
export default ValidationContextProvider
