import React, {
	FC,
	useContext,
	useMemo,
	useCallback,
	useState,
	ChangeEvent,
} from 'react'
import {
	Grid,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	Box,
	Typography,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@material-ui/core'
import Slider from 'sav-components/Slider'
import { makeStyles } from '@material-ui/core/styles'
import { PreprocessContext } from './PreprocessContextProvider'
import { useTitleStyles } from '../create-project/styles'

const useStyles = makeStyles((theme) => ({
	slider: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		padding: '10px 15px',
	},
}))

interface Props {
	isExtent?: boolean
}

const RGBSlider: FC<Props> = ({ isExtent }) => {
	const {
		state: { activePreRangesStat, preProcessRanges },
		actions: { setPreProcessRanges, setActivePreRangesStat },
	} = useContext(PreprocessContext)

	const classes = useStyles()
	const titleStyles = useTitleStyles()

	const [radioValue, setRadioValue] = useState<string | undefined>('')

	const handleRadioValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		setRadioValue((event.target as HTMLInputElement).value)
	}

	const sliderMin = -300
	const sliderMax = 3000

	// const local
	const handleChange = useCallback(
		(newValue: number[] | number, rangeName: string) =>
			activePreRangesStat &&
			preProcessRanges &&
			setPreProcessRanges({
				...preProcessRanges,
				[activePreRangesStat]: {
					...preProcessRanges[activePreRangesStat],
					[rangeName]: {
						low: newValue[0],
						high: newValue[1],
					},
				},
			}),
		[activePreRangesStat, preProcessRanges, setPreProcessRanges],
	)

	const currentRanges = useMemo(
		() =>
			preProcessRanges &&
			activePreRangesStat &&
			preProcessRanges[activePreRangesStat],
		[preProcessRanges, activePreRangesStat],
	)

	const rangesRed0 = useMemo(
		() => currentRanges !== '' && Number(currentRanges?.red.low),
		[currentRanges],
	)
	const rangesRed1 = useMemo(
		() => currentRanges !== '' && Number(currentRanges?.red.high),
		[currentRanges],
	)
	const rangesGreen0 = useMemo(
		() => currentRanges !== '' && Number(currentRanges?.green.low),
		[currentRanges],
	)
	const rangesGreen1 = useMemo(
		() => currentRanges !== '' && Number(currentRanges?.green.high),
		[currentRanges],
	)
	const rangesBlue0 = useMemo(
		() => currentRanges !== '' && Number(currentRanges?.blue.low),
		[currentRanges],
	)
	const rangesBlue1 = useMemo(
		() => currentRanges !== '' && Number(currentRanges?.blue.high),
		[currentRanges],
	)

	const handleSliderChange = useCallback(
		(value: string) => setActivePreRangesStat(value),
		[setActivePreRangesStat],
	)

	return currentRanges ? (
		<Grid container direction="column">
			{!isExtent && (
				<Box my={2} ml={2} mr={2}>
					{preProcessRanges && Object.keys(preProcessRanges).length < 5 && (
						<Box>
							<Typography
								variant="body2"
								gutterBottom
								className={titleStyles.subtitle}
							>
								Range statistics
							</Typography>
							<RadioGroup value={radioValue} onChange={handleRadioValueChange}>
								<Box style={{ display: 'flex', flexWrap: 'wrap' }}>
									{Object.keys(preProcessRanges).map((rangeKey) => (
										<Box key={rangeKey} style={{ width: '50%' }}>
											<FormControlLabel
												label={rangeKey}
												value={rangeKey}
												control={
													<Radio
														color="primary"
														checked={activePreRangesStat === rangeKey}
														onClick={(e) =>
															setActivePreRangesStat(
																(e.target as HTMLInputElement).value,
															)
														}
													/>
												}
											/>
										</Box>
									))}
								</Box>
							</RadioGroup>
						</Box>
					)}

					{preProcessRanges && Object.keys(preProcessRanges).length >= 5 && (
						<FormControl fullWidth variant="filled">
							<InputLabel id="demo-simple-select-label">
								Range statistics
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={activePreRangesStat}
								label="Range statistics"
								onChange={(e) => handleSliderChange(String(e.target.value))}
							>
								{preProcessRanges &&
									Object.keys(preProcessRanges).map((rangeName: string) => (
										<MenuItem key={`range-name-${rangeName}`} value={rangeName}>
											{rangeName}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					)}
				</Box>
			)}
			<Box className={classes.slider}>
				<Slider
					defaultValue={[Number(rangesRed0), Number(rangesRed1)]}
					title="R."
					min={sliderMin}
					max={sliderMax}
					step={1}
					getValueCommitted={(val: number | number) => handleChange(val, 'red')}
				/>
			</Box>
			<Box className={classes.slider}>
				<Slider
					defaultValue={[Number(rangesGreen0), Number(rangesGreen1)]}
					title="G."
					min={sliderMin}
					max={sliderMax}
					step={1}
					getValueCommitted={(val: number | number) =>
						handleChange(val, 'green')
					}
				/>
			</Box>
			<Box className={classes.slider}>
				<Slider
					defaultValue={[Number(rangesBlue0), Number(rangesBlue1)]}
					title="B."
					min={sliderMin}
					max={sliderMax}
					step={1}
					getValueCommitted={(val: number | number) =>
						handleChange(val, 'blue')
					}
				/>
			</Box>
		</Grid>
	) : null
}

export default RGBSlider
